/*---------------------Main Header Styles Start---------------------------*/
.main-header {
	position: fixed !important;
	top: 0 !important;
	right: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	background-color: var(--main-header-bg) !important;
	z-index: 112;
	padding: 0.75rem 1.875rem;
	padding-inline: 1.875rem !important;
	justify-content: flex-end;
	.logo-box{
		position: absolute;
		left: 1rem;
		max-width: 4.0625rem;
		img{
			width: 100%;
		}
	}

	.k-appbar-section {
		margin-left: 1.875rem;

		&.user-profile-circle {
			.k-dropdown-button {
				position: relative;

				&:before {
					position: absolute;
					content: '';
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					width: 2.875rem;
					min-width: 2.875rem;
					height: 2.875rem;
					border-radius: 1.4375rem;
					background-color: var(--profile-pic);
				}

				.k-button {
					width: 2.875rem;
					min-width: 2.875rem;
					height: 2.875rem;
					border-radius: 1.4375rem;
					border: 0 !important;
					box-shadow: none !important;
					outline: none !important;
					background-color: transparent !important;
					position: relative;
					z-index: 9;

					.k-button-text {
						color: var(--tertiary-color);
						font-size: 1.2775rem;
					}
				}
			}
		}

		&.notification-circle {
			.notification-btn {
				margin-top: 0.5rem;
				width: 2.25rem;
				min-width: 2.25rem;
				height: 2.25rem;
				border-radius: 1.125rem;
				border: 1px solid var(--border-color-tertiary) !important;
				box-shadow: none !important;
				outline: none !important;
				background-color: transparent !important;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				color: var(--notification-bell-color);
				cursor: pointer;
			}

			.badge {
				background-color: var(--danger-lite-color);
				color: var(--theme-text-notification);
				font-size: var(--fz-10px);
				line-height: 0.9375rem;
				min-width: 1.875rem;
				height: 1.0625rem;
				top:0.5rem;
				align-items: center;
				justify-content: center;
				display: inline-flex;
			}
		}
		&.file-failed-circle{
			.file-failed-btn {
				margin-top: 0.5rem;
				width: 8.75rem;
				min-width: 8.75rem;
				height: 2.25rem;
				border-radius: 1.125rem;
				border: 1px solid var(--border-color-tertiary) !important;
				box-shadow: none !important;
				outline: none !important;
				background-color: transparent !important;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				color: var(--theme-dark-color);
				font-size: var(--fz-14px);
				font-weight: 700;
				cursor: pointer;
				label{
					cursor: pointer;
				}
				svg{
					margin-right: 0.625rem;
					color: var(--primary-color);
				}
			}

			.badge {
				background-color: var(--danger-lite-color);
				color: var(--theme-text-notification);
				font-size: var(--fz-10px);
				line-height: 0.9375rem;
				min-width: 1.875rem;
				height: 1.0625rem;
				top:0.5rem;
				align-items: center;
				justify-content: center;
				display: inline-flex;
			}

		}
	}
}

.right-end-image {
	height: 2.875rem;
	width: auto;
	max-width: 2.875rem;
}

.language-switcher-wrapper {
	position: fixed;
	left: 7.1875rem;
	top: 1.3125rem;
	height: 2.375rem;
	width: 100%;
	max-width: 7.25rem;
	z-index: 113;
	.kWrapper{
		height: 100%;
	}

	.k-dropdownlist {
		width: 100%;
		height: 100%;
		padding: 0.5rem 0.5rem 0.5rem 1.1875rem;
		font-weight: 700;
		font-family: 'Gotham-Bold', sans-serif !important;
		font-size: var(--fz-14px);
		line-height: 1.3125rem;
		text-transform: uppercase;
		// -webkit-appearance: none;
		// -moz-appearance: none;
		// appearance: none;
		// background-image: url(../images/dropdown-arrow.svg);
		// background-repeat: no-repeat;
		// background-position: calc(100% - 1.25rem) 50%;
		border: 1px solid var(--default-dropdown-border-color) !important;
		background-color: var(--default-dropdown-bg-color) !important;
		border-radius: 0.25rem;
		color: var(--sidebar-item-expand) !important;

		.k-input-inner {
			padding: 0 !important;
		}
		.k-i-caret-alt-down::before {
			content: '\e015';
		}
	}
}

.k-animation-container {
	// min-width: 8.0625rem;

	.custom-lang-dropdown {
		position: relative;
		padding-top: 0.5rem !important;
		background: transparent !important;
		border: 0 !important;
		box-shadow: none !important;
		outline: none !important;
		border-radius: 0.471398125rem;
		// min-width: 8.0625rem;

		&:before {
			content: '';
			position: absolute;
			top: 0.55rem;
			right: calc(50% - 1.03125rem);
			width: 2.0625rem;
			height: 0.7rem;
			background-color: var(--kendo-group-bg-color);
			z-index: 11;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0.15rem;
			right: calc(50% - 0.53125rem);
			width: 0.7rem;
			height: 0.7rem;
			border-radius: 0.1875rem;
			box-shadow: 0px 0px 0.75rem rgba(0, 0, 0, 0.1) !important;
			transform: rotate(45deg);
			background-color: var(--kendo-group-bg-color);
			border: 1px solid var(--profile-dropdown-border);
			z-index: 10;
		}

		.k-list {
			border-radius: 0.471398125rem;
			background-color: var(--kendo-group-bg-color);
		}

		.k-list-ul {
			position: relative;
			width: 100%;
			border-radius: 0.471398125rem;
			border: 1px solid var(--profile-dropdown-border);
			box-shadow: 0px 0px 0.75rem rgba(0, 0, 0, 0.1) !important;
			z-index: 10;

			.k-list-item {
				padding: 0 !important;
				border-bottom: 0.125rem solid var(--profile-dropdown-border) !important;
				text-transform: uppercase;

				.k-list-item-text {
					padding: 0.75rem 1rem;
					font-size: 0.875rem;
					line-height: 0.875rem;
					color: var(--k-animation-link-color);
					font-weight: 600;
					display: flex;
					width: 100%;
					background-color: var(--kendo-group-bg-color) !important;
					text-transform: uppercase;
				}

				&:first-child {
					border-top-right-radius: 0.471398125rem;
					border-top-left-radius: 0.471398125rem;
				}

				&:last-child {
					border-bottom-right-radius: 0.471398125rem;
					border-bottom-left-radius: 0.471398125rem;
					border-bottom: 0 !important;
				}
			}
		}
	}
	&.k-chart-tooltip-wrapper{
		min-width: unset;
	}
}

.click-span {
	.form-control-switch-btn {
		height: auto !important;
		padding: 0 !important;
	}
	&.profile-click-span {
		padding: 0.3125rem 0.625rem !important;
	}
	.profile-details {
		display: flex;
		align-items: center;
		height: 2.5rem;
		padding: 0.25rem 0.625rem;
		background-color: var(--profile-details-active-color);
		border-radius: 0.25rem;
		position: relative;
		z-index: 10;
		.icon-circle {
			width: 1.8125rem;
			height: 1.8125rem;
			min-width: 1.8125rem;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: var(--profile-pic);
			overflow: hidden;
			margin-right: 0.625rem;
			.fa-user {
				color: var(--tertiary-color);
				font-size: 0.75rem;
			}
		}
		.profile-name {
			width: calc(100% - 2rem);
			display: inline-block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

/*---------------------Main Header Styles End---------------------------*/
/*---------------------Layout Styles Start---------------------------*/

.page-wrapper {
	height: 100%;
	position: relative;

	.logo-wrapper {
		position: absolute;
		top: 1.125rem;
		left: 0;
		padding: 0 1rem 0rem 0.625rem;
		z-index: 100;
	}

	.sidebar-toggle-btn {
		display: none;
		color: var(--theme-dark-color) !important;
	}
	

	.k-drawer-container {
		height: 100%;

		.k-widget.k-drawer {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			height: 100%;
			overflow: visible;
			padding-top: 1.125rem;
			z-index: 111;
			width: 5.3125rem;
			overflow-x: hidden;
			background-color: var(--sidebar-bg);
			border-right: 1px solid var(--sidebar-border-color);
			flex-basis: unset !important;
			-webkit-transition: all 0.4s ease-in-out;
			-moz-transition: all 0.4s ease-in-out;
			-ms-transition: all 0.4s ease-in-out;
			-o-transition: all 0.4s ease-in-out;
			transition: all 0.4s ease-in-out;

			.k-drawer-wrapper {
				width: unset !important;
				height: 100%;
				overflow: visible;

				.k-drawer-items {
					padding: 0 0.625rem;
					padding-bottom: 2rem;

					.k-drawer-item {
						height: 2.5625rem;
						padding: 0;
						margin: 0.75rem 0;
						display: flex;
						border-radius: 1.5625rem;
						background-color: transparent !important;
						flex-direction: column;
						align-items: flex-start;
						position: relative;
						gap: 0;
						&:hover{
							height: auto;
						}
						.menu-icon {
							position: relative;
							z-index: 11;
							color: var(--menu-icon);
							width: 4rem;
							min-width: 4rem;
							height: 2.5625rem;
							min-height: 2.5625rem;
							display: inline-flex;
							align-items: center;
							justify-content: center;
							-webkit-transition: all 0s ease-in-out;
							-moz-transition: all 0s ease-in-out;
							-ms-transition: all 0s ease-in-out;
							-o-transition: all 0s ease-in-out;
							transition: all 0s ease-in-out;
						}
						.item-descr-wrap {
							position: absolute;	
							width: 18.9rem;
							display: inline-flex;
							font-size: var(--fz-14px);
							color: var(--menu-icon);
							background-color: transparent;
							opacity: 1;
							-webkit-transition: all 0s ease-in-out;
							-moz-transition: all 0s ease-in-out;
							-ms-transition: all 0s ease-in-out;
							-o-transition: all 0s ease-in-out;
							transition: all 0s ease-in-out;
							white-space: nowrap;
							height: 2.5625rem;
							align-items: center;
							left: 0.625rem;
							padding-right: 1rem;
							padding-left: 4.6rem;
							padding-top: 0.1rem;
							border-radius: 0.471398125rem;
							&:hover{
								color: var(--theme-color) !important;			
							}
							&.dropdown-active {
								color: var(--theme-color) !important;
							}
						}
						.dropend {
							z-index: 10;
							width: 100%;
							-webkit-transition: all 0s ease-in-out;
							-moz-transition: all 0s ease-in-out;
							-ms-transition: all 0s ease-in-out;
							-o-transition: all 0s ease-in-out;
							transition: all 0s ease-in-out;

							.dropdown-toggle {
								position: absolute;
								right: -0.5rem;
								top: -2.5625rem;
								background-color: transparent !important;
								color: var(--secondary-color) !important;
								font-size: var(--fz-20px);
								border: 0 !important;
								padding: 0 !important;
								width: 1rem;
								max-width: 1rem;
								height: 2.5625rem;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 0.471rem !important;
								z-index: 101;
								-moz-transform: rotate(90deg);
								-webkit-transform: rotate(90deg);
								-o-transform: rotate(90deg);
								-ms-transform: rotate(90deg);
								transform: rotate(90deg);
								-webkit-transition: all 0s ease-in-out;
								-moz-transition: all 0s ease-in-out;
								-ms-transition: all 0s ease-in-out;
								-o-transition: all 0s ease-in-out;
								transition: all 0s ease-in-out;

							}

							.dropdown-menu {
								border-top-right-radius: 0.471rem;
								border-bottom-right-radius: 0.471rem;
								border-top-left-radius: 0;
								border: 0;
								top: 0 !important;
								left: 4.05rem !important;
								position: relative !important;
								transform: none !important;
								color: var(--secondary-color) !important;
								background-color: transparent !important;
								padding: 0;
								z-index: 100;
								height: 0;
								display: none !important;
								-webkit-transition: all 0s ease-in-out;
								-moz-transition: all 0s ease-in-out;
								-ms-transition: all 0s ease-in-out;
								-o-transition: all 0s ease-in-out;
								transition: all 0s ease-in-out;
								

								.dropdown-item {
									color: var(--menu-icon) !important;
									font-weight: 500;
									font-size: var(--fz-14px);
									line-height: 100%;
									padding: 0.5rem 1.1875rem 0.5rem 1.1875rem;
									background-color: transparent !important;
									position: relative;
									height: 2.5625rem;
									display: flex;
									align-items: center;
									-webkit-transition: all 0s ease-in-out;
									-moz-transition: all 0s ease-in-out;
									-ms-transition: all 0s ease-in-out;
									-o-transition: all 0s ease-in-out;
									transition: all 0s ease-in-out;

									span::before {
										content: '';
										height: 2px;
										width: calc(100% - 2.375rem);
										bottom: 0;
										left: 1.1875rem;
										position: absolute;
										background-color: var(--theme-light-color);
										opacity: 0.05;
									}
									&:last-child {
										span::before {
											display: none;
										}
									}
									&:hover {
										color: var(--theme-color) !important;
									}
									&.dropdown-active {
										color: var(--theme-color) !important;
									}
								}
							}

							.dropdown-menu.show {
								height: auto;
								display: none !important;
								width: 15.5rem;
								&:after {
									content: '';
									height: 2px;
									width: calc(100% - 2.375rem);
									left: 1.1875rem;
									top: 0;
									position: absolute;
									background-color: var(--theme-light-color);
									opacity: 0.05;
								}
								.dropdown-item {
									&:nth-child(1),
									&:last-child {
										border-top-right-radius: 0.471rem;
									}
									&:last-child {
										border-bottom-right-radius: 0.471rem;
										border-bottom-left-radius: 0.471rem;
									}
									&:nth-child(1) {
										position: relative;
										&:after {
											content: '';
											position: absolute;
											left: -0.5rem;
											top: 0rem;
											width: 0.5rem;
											height: 0.5rem;
										}
										&:before {
											content: '';
											position: absolute;
											left: -0.5rem;
											top: 0rem;
											width: 1rem;
											height: 1rem;
											background-color: var(--sidebar-bg);
											border-radius: 0.5rem;
											z-index: 9;
											-moz-transform: translateX(-0.52rem) translateY(0);
											-webkit-transform: translateX(-0.52rem) translateY(0);
											-o-transform: translateX(-0.52rem) translateY(0);
											-ms-transform: translateX(-0.52rem) translateY(0);
											transform: translateX(-0.52rem) translateY(0);
										}
									}
								}
							}
							&.no-first-menu{
								.dropdown-menu{
									top:0rem !important;
									&:before{
										top:0;
										border-top-right-radius: 0.471rem;
										border-bottom-right-radius: 0.471rem;
									}
									&.show{
										&:after{
											display: none;
										}
										.dropdown-item{
											&:nth-child(1){
												&:before, &:after{
													top:2.5625rem;
												}
											}
										}
										.only-item{
											&:before{
												border-bottom-right-radius: 0.471rem;
											}
											&:nth-child(1){
												&:before, &:after{
													display: none !important;
												}
										}
									}
									}
									
								}
							}
						}

						&:first-child {
							width: 4.0625rem;
							padding: 0;
							margin-top: 0;
							margin-bottom: 1.25rem;
							// background-color: var(--white-color) !important;
							background-color: transparent !important;
						}

						&.k-drawer-separator {
							display: none;
						}

						&:hover {
							.menu-icon {
								color: var(--theme-color);
							}
							.item-descr-wrap {
								opacity: 1;
								width: 18.9rem;
								display: inline-flex;
								&:hover {
									color: var(--sidebar-bg);
								}
							}
							.dropend {
								.dropdown-toggle{
									-moz-transform: rotate(-90deg);
									-webkit-transform: rotate(-90deg);
									-o-transform: rotate(-90deg);
									-ms-transform: rotate(-90deg);
									transform: rotate(-90deg);
								}
								.dropdown-menu.show,
								.dropdown-menu {
									display: block !important;
								}
							}
						}

						&.k-selected {
							background-color: transparent !important;
							.menu-icon {
								color: var(--theme-color);
							}
						}
					}
				}
			}
			
			&:hover{
				width: 21rem;
			}
		}
		&.k-drawer-expanded {
			.k-widget.k-drawer {
				width: 5.3125rem;
				overflow-x: hidden;
				&:hover{
					width: 21rem;
				}
			}
		}

		.k-drawer-content {
			padding: 0 0 0 5.375rem;
			margin-top: 5rem;
			height: calc(100% - 5rem);
			width: 100%;
			overflow: hidden;

			.page-content {
				height: 100%;

				.page-header {
					padding: 1.25rem 2rem 1.4375rem 1.9375rem;
					display: flex;
					justify-content: space-between;
					align-items: center;

					h3 {
						font-family: 'Gotham-Bold', sans-serif !important;
						font-weight: 700;
						font-size: var(--fz-20px);
						line-height: 1.625rem;
						color: var(--theme-text-color-primary);
						margin-bottom: 0;
						padding-right: 1rem;
						gap: 0.5rem;
    					display: inline-flex;
					}
					
					.icon-add-btn {
						padding: 0.375rem 1.375rem !important;
					}
					.back-btn-header {
						display: inline-flex;
						align-items: center;
						.back-btn {
							margin-right: 0.5rem;
							padding: 0 !important;
							border: 0 !important;
							background-color: transparent !important;
							color: var(--theme-text-color-primary);
						}
					}
					&.with-right-form{
						.default-form-style{
							margin-left: auto;
							.form-group{
								gap: 1.25rem;
							}
							.form-control{
								margin-right: 0 !important;
							}
						}
					}
				}

				.page-content-body {
					padding: 0rem 2rem 1.4375rem 1.9375rem;
					&.common-page-content-body {
						height: calc(100% - 4.4375rem);
					}
				}
			}
		}
	}
}

.box-container {
	background-color: var(--box-container-bg);
	border-radius: 0.625rem;
	&.common-box-container {
		height: 100%;
		padding: 1.1875rem 1.25rem 1.1875rem 1.875rem;
		&.with-scroll{
			padding-right: 0.5rem;
			.common-box-scroll{
				height: 100%;
				overflow-y: auto;
				padding-right: 0.75rem;
			}
		}
		
	}
}

/*---------------------Layout Styles End---------------------------*/

/*------------------Error Page Styles Start--------------------------*/
.error-page {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.error-body {
		max-width: 28.9375rem;

		h1 {
			font-size: var(--fz-36px);
			line-height: 2.9375rem;
			color: var(--theme-dark-color);
			font-weight: 700;
			text-align: center;
		}

		p {
			font-size: var(--fz-14px);
			line-height: 1.3125rem;
			text-align: center;
			color: var(--theme-text-color-primary);
		}
	}
}

/*------------------Error Page Styles End--------------------------*/

/*------------------No Data Styles Start--------------------------*/
.no-data {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--theme-light-color);
	border-radius: 0.625rem;

	.no-data-body {
		max-width: 38.875rem;
		max-height: 38.875rem;
		width: 100%;
		height: 100%;
		// border-radius: 19.4375rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		// overflow: hidden;

		.no-data-inner {
			max-width: 38.875rem;
			max-height: 38.875rem;
			width: 100%;
			height: 100%;
			// border-radius: 19.4375rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			z-index: 9;
		}

		// &:after {
		// 	position: absolute;
		// 	content: '';
		// 	max-width: 38.875rem;
		// 	max-height: 38.875rem;
		// 	width: 100%;
		// 	height: 100%;
		// 	border-radius: 19.4375rem;
		// 	left: 0;
		// 	right: 0;
		// 	top: 0;
		// 	bottom: 0;
		// 	background: var(--body-color);
		// 	opacity: 0.33;
		// }

		h1 {
			font-size: var(--fz-36px);
			line-height: 2.9375rem;
			color: var(--theme-dark-color);
			font-weight: 700;
			text-align: center;
			margin-top: 2.25rem;
		}

		p {
			font-size: var(--fz-14px);
			line-height: 1.3125rem;
			text-align: center;
			color: var(--theme-text-color-primary);
			max-width: 28.9375rem;
		}
	}
}

/*------------------No Data Styles End--------------------------*/

.header-popover{
	min-width: 15rem;
	max-width: 33.4375rem;
	width: 100%;
	max-height: 47.5rem;
	height: 100%;
	margin-left: -10rem;
	.k-popover{
		height: 100%;
		background-color: var(--theme-light-color) !important;
	}
	.k-popover-callout.k-callout-n{
		left: 80.25%;
	}
	.k-popover-body{
		height: 100%;
		padding: 1rem 0.5rem 1rem 1rem;
		h3{
			font-size: var(--fz-20px);
			line-height: 1.875rem;
			color: var(--theme-text-color-primary);
			font-weight: 700;
			display: flex;
			justify-content: space-between;
			.mark-all-read{
				font-size: var(--fz-12px) !important;
				font-weight: 700;
				line-height: 1.125rem;
				text-decoration-line: underline;
				color: var(--primary-color);
				cursor: pointer;
				margin-left: 1rem;
				margin-right: 0.5rem;
				padding: 0 !important;
				background-color: transparent !important;
				border: 0 !important;
				box-shadow: none !important;
				outline: none !important;
				border-radius: 0 !important;
				white-space: nowrap;
			}
		}
		& > div {
			height: calc(100% - 2rem);
		}
		.view-all {
			font-size: var(--fz-12px) !important;
			font-weight: 700;
			line-height: 1.125rem;
			text-decoration-line: underline;
			color: var(--primary-color);
			cursor: pointer;
			margin: 0.5rem auto;
			display: block;
			padding: 0 !important;
			background-color: transparent !important;
			border: 0 !important;
			box-shadow: none !important;
			outline: none !important;
			border-radius: 0 !important;
			white-space: nowrap;
			text-transform: unset !important;
		}
		.inline-list-card{
			height: 100%;
			padding-right: 0.5rem;
			overflow: auto;
		}
		.text-danger{
			font-weight: 700;
			font-size: var(--fz-12px) !important;
			height: 100%;
			align-items: center;
			justify-content: center;
			display: flex;
		}
		.notification-list-card-wrapper .notifictaion-item .list-title{
			max-width: 100% !important;
		}
	}
}
.light-theme{
	.header-popover{
		.k-popover{
			filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.11));
		}
	}
}
.dark-theme{
	.header-popover{
		.k-popover{
			filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.11));
		}
	}
}