.light-theme {
	--theme-color: #e7643b;
	--theme-color-rgb: 231, 100, 59;
	--theme-dark-color: #191f28;
	--theme-light-color: #ffffff;
	--primary-color: #1268b3;
	--primary-dark-color: #004b8d;
	--secondary-color: #767579;
	--secondary-dark-color: #161e34;
	--tertiary-color: #b3bcc3;
	--tertiary-dark-color: #007bff;
	--success-color: #34ad4d;
	--success-dark-color: #11a02d;
	--success-light-color: #eaf5ea;
	--danger-color: #d71635;
	--danger-lite-color: #ff3333;
	--danger-dark-color: #e90000;
	--warning-lite-color: #ffebeb;
	--warning-color: #fd5f00;
	--warning-color-rgba: 253, 95, 0;
	--warning-dark-color: #e7643b;
	--info-color: #89c4f4;
	--info-color-rgb: 137, 196, 244;
	--info-lite-color: #bee1fd;
	--info-dark-color: #007bff;
	--light-color: #e5e5e5;
	--light-color-rgb: 229, 229, 229;
	--dark-color: #6978a0;
	--disabled-color: #b7b7b7;
	--border-color: #f2f4f9;
	--border-color-primary: #e4eaf0;
	--border-color-secondary: #b8becc;
	--border-color-secondary-rgb: 184, 190, 204;
	--border-color-tertiary: #e8edf1;
	--theme-canvas-title-border: #e5e5e5;
	--white-color: #ffffff;
	--black-color: #000000;
	--theme-black-text-color: #000000;
	--body-color: #f5f5f5;
	--body-color-rgb: 245, 245, 245;
	--grid-header-bg-color: #f5f5f5;
	--grid-header-text-color: #56565b;
	--grid-body-bg-color: #ffffff;
	--grid-body-diabled-bg-color: #e5e5e5;
	--grid-body-text-color: #191f28;
	--grid-border-seperator: #f2f6f9;
	--grid-pg-btn-border: #e4eaf0;
	--grid-pg-btn-color: #949496;
	--grid-file-btn-color: #949496;
	--grid-pg-btn-bg: #ffffff;
	--theme-text-color-primary: #35343a;
	--theme-text-color-secondary: #56565b;
	--theme-text-color-tertiary: #f5f5f5;
	--input-text-color: #999999;
	--scrollbar-thumb: #dae0ea;
	--scrollbar-thumb-hover: #cbd5e4;
	--scrollbar-track: transparent;
	--scrollbar-thumb-2: #a9afb9;
	--scrollbar-thumb-hover-2: #9197a0;
	--scrollbar-track-2: transparent;
	--checkbox-uncheck-border: #949496;
	background-color: var(--body-bg);
	--toastify-warning-color: #d71635;
	--accordian-icon: #424d68;
	--selected-list-item-hover-color: #424242;
	--list-item-hover-color: #feeee9;
	--form-control-bg: #ffffff;
	--dsb-reject-btn: #ff0000;
	--notification-read: #f9f9f9;
	--custom-tile-bg-rgba: var(--body-color-rgb);
	--file-upload-bg: #ffffff;
	--file-upload-border-color: var(--grid-pg-btn-border);
	--file-color: #dd5c5c;
	--count-bg-2: #e5e5e5;
	--count-bg-2-rgb: 229, 229, 229;
	--toast-msg-color: #191f28;
	--kendo-grid-header: #56565b;

	/*------theme-switch-colors---------*/
	--main-header-bg: #ffffff;
	--sidebar-bg: #ffffff;
	--sidebar-border-color: #f2f4f9;
	--body-bg: #f5f5f5;
	--box-container-bg: #ffffff;
	--dashboard-msg: #191f28;
	--sidebar-item-expand: #191f28;
	--sidebar-menu-icon: #767579;
	--default-dropdown-bg-color: #ffffff;
	--default-dropdown-border-color: #f5f5f5;
	--default-dropdown-selected-bg-color: 251, 149, 117;
	--profile-pic: #e5e5e5;
	--profile-dropdown: #f5f5f5;
	--menu-icon: #767579;
	--notification-bell-color: #6978a0;
	--kendo-group-bg-color: #ffffff;
	--k-animation-link-color: #56565b;
	--profile-details-active-color: rgba(245, 245, 245, 0.7);
	--profile-dropdown-border: #f5f5f5;
	--offcanvas-body-bg: #ffffff;
	--grid-action-btn: #191f28;
	--theme-text-notification: #e5e5e5;
	--multisect-border: #f5f5f5;
	--multiselect-hover: #ffffff;
	--filter-popup: #ffffff;
	--theme-signin-inactive: #767579;
	--theme-bg-color: #ffffff;
	--card-color-bg: #ffffff;
	--badge-color: #f5f5f5;
	--input-placeholder: #848a99;
	/*------theme-switch-colors---------*/
	height: 100%;
}

.dark-theme {
	--theme-color: #e7643b;
	--theme-color-rgb: 231, 100, 59;
	--theme-dark-color: #f5f5f5;
	--theme-light-color: #191f28;
	--primary-color: #1268b3;
	--primary-dark-color: #004b8d;
	--secondary-color: #767579;
	--secondary-dark-color: #f5f5f5;
	--tertiary-color: #50565e;
	--tertiary-dark-color: #007bff;
	--success-color: #34ad4d;
	--success-dark-color: #11a02d;
	--success-light-color: #eaf5ea;
	--danger-color: #d71635;
	--danger-lite-color: #ff3333;
	--danger-dark-color: #e90000;
	--warning-lite-color: #ffebeb;
	--warning-color: #fd5f00;
	--warning-color-rgba: 253, 95, 0;
	--warning-dark-color: #e7643b;
	--info-color: #89c4f4;
	--info-color-rgb: 137, 196, 244;
	--info-lite-color: #bee1fd;
	--info-dark-color: #007bff;
	--light-color: #242a33;
	--light-color-rgb: 36, 42, 51;
	--dark-color: #6978a0;
	--disabled-color: #b7b7b7;
	--border-color: #f2f4f9;
	--border-color-primary: #35343a;
	--border-color-secondary: #191f28;
	--border-color-secondary-rgb: 39, 46, 57;
	--border-color-tertiary: #35343a;
	--theme-canvas-title-border: #191f28;
	--white-color: #ffffff;
	--black-color: #000000;
	--theme-black-text-color: #f5f5f5;
	--body-color: #0e131a;
	--body-color-rgb: 14, 19, 26;
	--grid-header-bg-color: #0e131a;
	--grid-header-text-color: #56565b;
	--grid-body-bg-color: #191f28;
	--grid-body-text-color: #ffffff;
	--grid-body-diabled-bg-color: #2f343b;
	--grid-border-seperator: #0e131a;
	--grid-pg-btn-border: #191f28;
	--grid-pg-btn-color: #949496;
	--grid-file-btn-color: #ffffff;
	--grid-pg-btn-bg: #191f28;
	--theme-text-color-primary: #f5f5f5;
	--theme-text-color-secondary: #ffffff;
	--theme-text-color-tertiary: #56565b;
	--input-text-color: #999999;
	--scrollbar-thumb: #0f151d;
	--scrollbar-thumb-hover: #636364;
	--scrollbar-track: #242a33;
	--scrollbar-thumb-2: #0f151d;
	--scrollbar-thumb-hover-2: #636364;
	--scrollbar-track-2: #242a33;
	--checkbox-uncheck-border: #949496;
	background-color: var(--body-bg);
	--toastify-warning-color: #d71635;
	--accordian-icon: #f5f5f5;
	--selected-list-item-hover-color: #424242;
	--list-item-hover-color: rgba(253, 95, 0, 0.15);
	--form-control-bg: #0e131a;
	--custom-tile-bg-rgba: var(--body-color-rgb);
	--file-upload-bg: rgba(var(--body-color), 0.63);
	--file-upload-border-color: #2b2e32;
	--file-color: #dd5c5c;
	--count-bg-2: #12171f;
	--count-bg-2-rgb: 18, 23, 31;
	--toast-msg-color: #191f28;
	--kendo-grid-header: #e7643b;
	/*------theme-switch-colors---------*/
	--main-header-bg: #191f28;
	--sidebar-bg: #191f28;
	--sidebar-border-color: #272e39;
	--body-bg: #0e131a;
	--box-container-bg: #191f28;
	--dashboard-msg: #ffffff;
	--sidebar-item-expand: #ffffff;
	--sidebar-menu-icon: #ffffff;
	--default-dropdown-bg-color: #0e131a;
	--default-dropdown-border-color: #272e39;
	--default-dropdown-selected-bg-color: 251, 149, 117;
	--profile-pic: #0e131a;
	--profile-dropdown: #0e131a;
	--menu-icon: #f5f5f5;
	--notification-bell-color: #6978a0;
	--kendo-group-bg-color: #0e131a;
	--k-animation-link-color: #f5f5f5;
	--profile-details-active-color: #191f28;
	--profile-dropdown-border: #191f28;
	--offcanvas-body-bg: #242a33;
	--grid-action-btn: #ffffff;
	--theme-text-notification: #f5f5f5;
	// --sidebar-item-expand: #191F28;
	// --sidebar-menu-icon: #767579;
	// --menu-icon: #767579;
	// --sidebar-bg: #FFFFFF;
	--multisect-border: #191f28;
	--multiselect-hover: #0e131a;
	--filter-popup: #0e131a;
	--theme-signin-inactive: #191f28;
	--theme-bg-color: #191f28;
	--card-color-bg: #191f28;
	--badge-color: #f5f5f5;
	--dsb-reject-btn: #ff0000;
	.tooltip {
		--bs-tooltip-color: #000;
		--bs-tooltip-bg: #fff;
	}

	/*------theme-switch-colors---------*/
	height: 100%;
}

/*------------Font Size Styles Start--------------*/
.light-theme,
.dark-theme {
	--fz-10px: 0.625rem;
	--fz-11px: 0.6875rem;
	--fz-12px: 0.75rem;
	--fz-13px: 0.8125rem;
	--fz-14px: 0.875rem;
	--fz-15px: 0.9375rem;
	--fz-16px: 1rem;
	--fz-17px: 1.0625rem;
	--fz-18px: 1.125rem;
	--fz-19px: 1.1875rem;
	--fz-20px: 1.25rem;
	--fz-21px: 1.3125rem;
	--fz-22px: 1.375rem;
	--fz-23px: 1.4375rem;
	--fz-24px: 1.5rem;
	--fz-25px: 1.5625rem;
	--fz-26px: 1.625rem;
	--fz-27px: 1.6875rem;
	--fz-28px: 1.75rem;
	--fz-29px: 1.8125rem;
	--fz-30px: 1.875rem;
	--fz-31px: 1.9375rem;
	--fz-32px: 2rem;
	--fz-33px: 2.0625rem;
	--fz-34px: 2.125rem;
	--fz-35px: 2.1875rem;
	--fz-36px: 2.25rem;
	--fz-37px: 2.3125rem;
	--fz-38px: 2.375rem;
	--fz-39px: 2.4375rem;
	--fz-40px: 2.5rem;
	--fz-41px: 2.5625rem;
	--fz-42px: 2.625rem;
	--fz-43px: 2.6875rem;
	--fz-44px: 2.75rem;
	--fz-45px: 2.8125rem;
	--fz-46px: 2.875rem;
	--fz-47px: 2.9375rem;
	--fz-48px: 3rem;
	--fz-49px: 3.0625rem;
	--fz-50px: 3.125rem;
}

/*------------Font Size Styles End--------------*/
