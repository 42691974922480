.light-theme {
  .login-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/people-white-isolating-costumes-working-laboratory.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
      position: fixed;
      content: "";
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #FF0000;
      /* Old browsers */
      background: -moz-linear-gradient(292.43deg, #FF0000 -211.87%, #000000 78.77%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(292.43deg, #FF0000 -211.87%, #000000 78.77%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(292.43deg, #FF0000 -211.87%, #000000 78.77%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF0000', endColorstr='#000000', GradientType=1);
      /* IE6-9 fallback on horizontal gradient */
      opacity: 0.85;
    }

    .login-container {
      width: 100%;
      height: 100%;
      max-width: 62.875rem;
      max-height: 31.875rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      h3 {
        font-size: var(--fz-40px);
        line-height: 3.25rem;
        color: var(--theme-text-color-tertiary);
        width: calc(100% - 2rem);
      }
    }

    .login-form {
      max-width: 26.1875rem;
      min-width: 26.1875rem;
      max-height: 31.875rem;
      height: 100%;
      width: 100%;
      background-color: var(--white-color);
      padding: 3.75rem 3.3125rem 4.125rem 3.3125rem;
      position: relative;
      border-radius: 0.625rem;

      h3 {
        font-size: var(--fz-24px);
        line-height: 2.7rem;
        font-weight: 600;
        margin-bottom: 0 !important;
        color: var(--grid-body-text-color);
      }

      h6 {
        font-size: var(--fz-14px);
        line-height: 1.575rem;
        font-weight: 600;
        margin-bottom: 1.375rem !important;
      }

      .form-group {
        padding-bottom: 1.5625rem;

        .form-label {
          font-size: var(--fz-14px);
          line-height: 1.3125rem;
          font-weight: 600;
          color: var(--theme-text-color-secondary);
          margin-bottom: 0.1875rem;
        }

        .form-control {
          background-color: var(--white-color);
          border: 1px solid var(--border-color-secondary);
          border-radius: 0.3125rem;
          padding: 0.5125rem 1rem;
          font-weight: 600;
          font-size: var(--fz-14px);
          line-height: 1.3125rem;
          color: var(--theme-dark-color);
          height: 2.75rem;
          font-family: "Gotham-Book", sans-serif !important;
        }

        .show-password-input {
          padding-right: 2.25rem;

          &::-ms-reveal,
          &::-ms-clear {
            display: none;
          }
        }

        .show-password {
          position: absolute;
          right: 0.5rem;
          bottom: 2.15rem;
          color: var(--input-text-color);
        }
      }

      .remeber-reset-password-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        .weblink-invalid{
          position: relative;
          height:1.5rem;
          display: flex;
          width: 100%;
          .error-msg-wrapper{
            position:absolute;
            top:0;
            left: 0;
          }
        }

        .k-checkbox-label {
          font-weight: 400;
          font-size: var(--fz-12px);
          line-height: 150%;
          color: var(--theme-text-color-secondary);
          font-family: "Gotham-Book", sans-serif !important;

        }

        .forgot-password-btn {
          font-size: var(--fz-12px);
          font-weight: 600;
          color: var(--primary-color);
          text-decoration: underline;
          padding: 0;
          border: 0 !important;
          background-color: transparent !important;
          -webkit-transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -ms-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;

          &:hover {
            color: var(--primary-dark-color);
          }
        }
        &.countdown-text{
          display: flex;
          align-items: center;
          margin-bottom: 0;
          p{
            margin-bottom: 0;
          }
        }
      }

      &.forgot-form,
      &.reset-form, &.otp-form {
        height: auto;
        max-height: unset;
        min-height: unset;

        .k-form-buttons {
          margin-top: 0 !important;
        }
      }

    }

    .login-footer {
      position: absolute;
      left: 3.75rem;
      right: 6.75rem;
      bottom: 2.5625rem;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 10.5rem);

      .left-box {
        p {
          font-weight: 400;
          font-size: var(--fz-14px);
          line-height: 1.3125rem;
          color: var(--theme-text-color-tertiary);
          margin: 0;
          font-family: "Gotham-Book", sans-serif !important;
        }
      }

      .right-box {
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;

          li {
            font-weight: 400;
            font-size: var(--fz-12px);
            line-height: 1.125rem;
            color: var(--theme-text-color-tertiary);

            &.list-pipe {
              margin: 0 1.5rem;
            }
          }
        }
      }

    }
  }
}

.default-loading{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-box-sm{
  display: none;
  text-align: center;
}


.otp-group {
	display: flex;
	width: 100%;
  justify-content: space-between;
  // padding-bottom: 1rem !important;
}

.otp-input {
	min-width: 2.5rem;
	height: 2.5rem;
  width: 2.5rem;
	border: 1px solid #ccc;
	border-radius: 0.313rem;
	text-align: center;
	font-size: var(--fz-26px);
	font-weight: bold;
	line-height: 1;
}

/*-----------------Responsive Styles Start-----------------------*/

@media (max-width: 1440px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  html {
    font-size: 13px;
  }

  .light-theme {
    .login-wrapper {
      .login-container {
        max-width: 55rem;
      }
    }
  }
}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

  html {
    font-size: 13px;
  }

  .light-theme {
    .login-wrapper {
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
      .login-container {
        max-width: 100%;
        max-height: 100%;
        padding: 5rem 2rem;
        justify-content: center;

        .left-box {
          display: none;
        }
      }

      .login-form {
        min-width: 20rem;
        max-width: 20rem;
        max-height: 30.875rem;
        min-height: 30.875rem;
        padding: 1.75rem 1.3125rem 2.125rem 1.3125rem;
      }

      .login-footer {
        position: relative;
        left: unset;
        right: unset;
        bottom: unset;
        padding: 1rem 0.5rem;
        width: calc(100% - 1rem);
        flex-direction: column-reverse;

        .right-box ul li {
          margin: 1rem 0.5rem;
        }
      }
    }
    
    .logo-box-sm{
      display: block;
    }
  }
}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

  html {
    font-size: 12px;
  }

  .light-theme {
    .login-wrapper {

      .login-container {
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        margin: 3rem 0rem;

        .left-box {
          width: 100%;
          text-align: center;

          .logo-container {
            img {
              max-width: 6.25rem;
            }
          }
        }
      }

      .login-form {

        &.forgot-form,
        &.reset-form {
          min-height: auto;
        }
      }

      .carousel {
        display: none;
      }

      .login-footer {

        .right-box ul li {
          margin: 0.5rem 0.35rem !important;
        }
      }
    }
  }
}

/*-----------------Responsive Styles End-----------------------*/