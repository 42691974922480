@font-face {
	font-family: 'Gotham-Medium', sans-serif !important;
	src: url(../fonts/Gotham-Medium.otf) format('otf');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham-Bold', sans-serif !important;
	src: url(../fonts/Gotham-Bold.otf) format('otf');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham-Book', sans-serif !important;
	src: url(../fonts/Gotham-Book.otf) format('otf');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

* {
	box-shadow: none !important;
	outline: none !important;
	box-sizing: border-box;
	font-family: 'Gotham-Medium', sans-serif;
}

html {
	font-size: 16px;
}

html,
body {
	height: 100%;
	font-family: 'Gotham-Medium', sans-serif !important;
	font-weight: 400;
	overflow: hidden;

	#root {
		height: 100%;
	}
}

body {
	margin: 0;
	padding: 0;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p,
img,
ul,
li,
a {
	margin: 0;
	padding: 0;
}
ul {
	margin: 0 !important;
}
.mb-10px {
	margin-bottom: 0.625rem !important;
}
.mb-7px {
	margin-bottom: 0.4375rem !important;
}
.mb-21px {
	margin-bottom: 1.3125rem !important;
}
.mt-9px {
	margin-top: 0.5625rem !important;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--input-text-color);
	font-size: var(--fz-12px);
	font-weight: 400;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: var(--input-text-color);
	font-size: var(--fz-12px);
	font-weight: 400;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: var(--input-text-color);
	font-size: var(--fz-12px);
	font-weight: 400;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: var(--input-text-color);
	font-size: var(--fz-12px);
	font-weight: 400;
}
input[type='password' i] {
	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}
}
.cursor-not-allowed {
	cursor: not-allowed;
	label {
		cursor: not-allowed;
	}
}
.hide-carousel-slide {
	display: none !important;
}

.kWrapper {
	width: 100%;
	&.disabled {
		cursor: not-allowed !important;
		.disabled {
			opacity: 1 !important;
			background-color: var(--body-color) !important;
		}
	}
}
.no-data-text {
	color: var(--theme-text-color-secondary);
}

/*------------Button Styles Start--------------*/

.btn {
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem !important;
	text-transform: capitalize;
	cursor: pointer;
	font-weight: 700 !important;
	font-family: 'Gotham-Bold', sans-serif !important;
	border: 0 !important;
	border-radius: 0.375rem !important;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;

	&.h-44px {
		height: 2.75rem;
	}
	&.sign-btn {
		max-width: 11rem !important;
	}

	&.icon-add-btn {
		width: 11.25rem;
		min-width: 11.25rem;
		text-align: center;

		svg {
			margin-right: 0.9375rem;
		}
	}

	&.btn-primary {
		background-color: var(--primary-color);
		color: var(--white-color);

		&:hover,
		&:focus {
			background-color: var(--primary-dark-color);
			color: var(--white-color);
		}
	}

	&.btn-secondary {
		background-color: var(--secondary-color);
		color: var(--theme-signin-inactive);

		&:hover,
		&:focus {
			background-color: var(--secondary-dark-color);
			color: var(--theme-signin-inactive);
		}
	}

	&.btn-tertiary {
		background-color: var(--tertiary-color);
		color: var(--white-color);

		&:hover,
		&:focus {
			background-color: var(--tertiary-dark-color);
			color: var(--white-color);
		}
	}

	&.btn-success {
		background-color: var(--success-color);
		color: var(--white-color);

		&:hover,
		&:focus {
			background-color: var(--success-dark-color);
			color: var(--white-color);
		}
	}

	&.btn-danger {
		background-color: var(--danger-lite-color);
		color: var(--white-color);

		&:hover,
		&:focus {
			background-color: var(--danger-dark-color);
			color: var(--white-color);
		}
	}

	&.btn-warning {
		background-color: var(--warning-color);
		color: var(--white-color);

		&:hover,
		&:focus {
			background-color: var(--warning-dark-color);
			color: var(--white-color);
		}
	}

	&.btn-info {
		background-color: var(--info-color);
		color: var(--white-color);

		&:hover,
		&:focus {
			background-color: var(--info-dark-color);
			color: var(--white-color);
		}
	}

	&.btn-outline-primary {
		background-color: var(--white-color) !important;
		color: var(--primary-color);
		border: 1px solid var(--primary-color) !important;

		&:hover,
		&:focus {
			background-color: var(--primary-color) !important;
			color: var(--white-color);
			border: 1px solid var(--primary-dark-color) !important;
		}
	}

	&.btn-outline-secondary {
		background-color: var(--theme-light-color);
		color: var(--theme-text-color-secondary);
		border: 1px solid var(--secondary-color) !important;

		&:hover,
		&:focus {
			background-color: var(--theme-text-color-secondary);
			color: var(--theme-light-color);
		}
	}

	&.btn-outline-danger {
		background-color: var(--white-color) !important;
		color: var(--danger-lite-color);
		border: 1px solid var(--danger-lite-color) !important;

		&:hover,
		&:focus {
			background-color: var(--danger-lite-color) !important;
			color: var(--white-color);
			border: 1px solid var(--danger-dark-color) !important;
		}
	}
	&.btn-icon {
		background-color: transparent !important;
		color: var(--secondary-dark-color);
		padding: 0 !important;
		height: 1.25rem;
		&:hover,
		&:focus {
			background-color: transparent !important;
			color: var(--secondary-dark-color);
		}
		&.disabled {
			opacity: 0.5 !important;
			cursor: not-allowed;
			position: relative;
			pointer-events: unset;
		}
	}
}

.import-btn-wrapper {
	position: relative;
	margin-left: auto;
	.note {
		position: absolute;
		font-size: 0.625rem;
		right: 0;
		bottom: -2rem;
		white-space: nowrap;
		font-size: var(--fz-11px) !important;
		font-weight: 600;
		color: var(--theme-dark-color) !important;
	}
}
.import-btn {
	position: relative;
	background-color: var(--primary-color) !important;
	color: var(--white-color) !important;
	overflow: hidden;
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
	cursor: pointer !important;

	&:hover,
	&:focus {
		background-color: var(--primary-dark-color) !important;
		color: var(--white-color) !important;
	}
	.fileInputBtn {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		cursor: pointer !important;
	}
}
::-webkit-file-upload-button {
	cursor: pointer;
}

/*------------Button Styles End--------------*/

.bgWhite {
	background-color: var(--white-color);
}
.theme-bg-color {
	background-color: var(--theme-bg-color);
}
.theme-body-bg-color {
	background-color: var(--body-bg);
}

.border-1 {
	border: 1px solid var(--border-color-tertiary);
}
.border-radius-5px {
	border-radius: 0.313rem;
}
/*------------Text Color Styles Start--------------*/

body {
	.text-primary {
		color: var(--primary-color) !important;
	}

	.text-secondary {
		color: var(--secondary-color) !important;
	}

	.text-tertiary {
		color: var(--tertiary-color) !important;
	}

	.text-success {
		color: var(--success-color) !important;
	}

	.text-danger {
		color: var(--danger-lite-color) !important;
	}

	.text-warning {
		color: var(--warning-color) !important;
	}

	.text-info {
		color: var(--info-color) !important;
	}
}

/*------------Text Color Styles End--------------*/

.k-switch-on .k-switch-track {
	border-color: var(--danger-lite-color);
	color: var(--white-color);
	background-color: var(--danger-lite-color);
}

.k-switch-off .k-switch-thumb {
	border-color: var(--border-color-secondary);
	color: #424242;
	background-color: var(--white-color);
}

/*------------Default Form Styles Start--------------*/
.form-control {
	&.disabled {
		background-color: var(--body-color) !important;
		opacity: 1 !important;
		cursor: not-allowed !important;
	}
}
.form-group {
	position: relative;
	padding-bottom: 1.25rem;

	.error-msg {
		font-size: var(--fz-12px) !important;
		font-weight: 600;
		color: var(--danger-lite-color) !important;
		position: absolute;
		bottom: 0.5rem;
		right: 0;
		margin: 0 !important;

		&.pos-left {
			display: inline-flex;
			align-items: center;
			padding-left: 0.9rem;
			line-height: 1rem;

			&::before {
				content: '';
				background-image: url(../images/error-icon.svg);
				background-size: 100% !important;
				background-position: center;
				background-repeat: no-repeat;
				width: 0.75rem;
				height: 0.75rem;
				position: absolute;
				left: 0;
			}
		}
	}
	&.password-form-group {
		.form-control {
			padding-right: 2.5rem !important;
		}
		.show-password {
			position: absolute;
			right: 0.75rem;
			bottom: 2.1rem;
		}
	}
}

.form-control {
	border-color: var(--border-color-secondary) !important;

	&:focus {
		border-color: var(--border-color-secondary) !important;
	}
}

.default-form-style {
	.input-type-number {
		position: relative;
		input[type='number'] {
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				opacity: 1;
			}
		}
		&.no-separator {
			&::before {
				width: unset;
			}
		}
		&:before {
			position: absolute;
			content: '';
			right: 3rem;
			top: 0;
			width: 1px;
			height: 100%;
			background-color: var(--border-color-secondary);
		}
		.error-msg {
			font-size: var(--fz-12px) !important;
			font-weight: 600;
			color: var(--danger-lite-color) !important;
			position: absolute;
			bottom: -1rem;
			right: 0;
			margin: 0 !important;
		}
		&.type-two {
			.input-number-error {
				.error-msg.pos-left {
					bottom: 0.5rem !important;
				}
			}
			&:before {
				top: unset;
				bottom: 1.5625rem;
				height: 2.75rem;
			}
		}
	}

	.position-relative-item {
		position: relative;
		.error-msg {
			font-size: var(--fz-12px) !important;
			font-weight: 600;
			color: var(--danger-lite-color) !important;
			position: absolute;
			bottom: -1rem;
			right: 0;
			margin: 0 !important;
		}
	}

	.form-group {
		padding-bottom: 1.5625rem;

		.form-label {
			font-weight: 600;
			font-size: var(--fz-14px);
			line-height: 150%;
			color: var(--theme-text-color-secondary);
			margin-bottom: 0.1875rem;
			.info-icon {
				margin-left: 0.25rem;
			}
			&.empty-label {
				opacity: 0;
				&.bordered {
					border: 1px solid var(--border-color);
				}
			}
		}
		.form-label + span {
			color: var(--theme-text-color-secondary);
		}

		.form-control {
			background-color: var(--form-control-bg);
			border: 1px solid var(--border-color-secondary);
			border-radius: 0.3125rem;
			padding: 0.5125rem 1rem;
			font-weight: 600;
			font-size: var(--fz-14px);
			line-height: 1.3125rem;
			color: var(--theme-dark-color);
			height: 2.75rem;
			font-family: 'Gotham-Book', sans-serif !important;

			&.text-area {
				height: auto;
				resize: none;
			}
		}

		.default-multi-select {
			background-color: var(--form-control-bg);
			border: 1px solid var(--border-color-secondary);
			border-radius: 0.3125rem;
			padding: 0.8125rem 1rem;
			font-weight: 500;
			font-size: var(--fz-12px);
			color: var(--input-text-color);
			height: 5.5625rem;

			&.h-44px {
				height: 2.75rem;
				max-height: 2.75rem;
				padding: 0.6rem 1rem;
			}
			&.h-auto {
				max-height: 5.5625rem;
				min-height: 2.75rem;
				padding: 0.55rem 1rem;
				overflow-y: auto;
			}
			&.k-disabled {
				background-color: var(--body-color) !important;
				opacity: 1 !important;
				cursor: not-allowed !important;
				.k-input-values .k-chip-list .k-chip {
					opacity: 0.7;
				}
			}

			span.k-clear-value {
				display: none;
			}

			.k-input-values {
				align-items: flex-start;
				flex-direction: row-reverse;
				padding: 0;

				.k-input-inner {
					padding-block: unset;
					padding-inline: unset;
					font-weight: 600;
					font-size: var(--fz-14px);
					line-height: 1.3125rem;
					color: var(--theme-dark-color);
					min-height: 1.75rem;
				}

				.k-chip-list {
					.k-chip {
						background-color: var(--theme-dark-color);
						border-radius: 1.0625rem;
						padding: 0.175rem 0.8125rem;
						height: 1.5rem;
						gap: 0;
						margin-left: 0.6rem;

						.k-chip-content {
							padding: 0;
							margin: 0;
						}

						.k-chip-label {
							color: var(--theme-text-color-tertiary);
							font-weight: 500;
							font-size: var(--fz-12px);
							line-height: 0.75rem;
							margin-right: 0.5rem;
						}

						.k-chip-actions {
							.k-i-x-circle {
								color: var(--theme-text-color-tertiary);
								&:before {
									content: '\e11c';
								}
							}
						}
					}
				}
			}
		}

		.default-select-dropdown {
			padding: 0.5125rem 0.5rem 0.5125rem 1rem;
			display: flex;
			align-items: center;
			.k-input-inner {
				padding-block: unset;
				padding-inline: unset;
				width: calc(100% - 1.5rem);
				display: inline-flex;
			}
			.k-input-button {
				width: 1.5rem;
			}
			.k-i-caret-alt-down::before {
				content: '\e015';
			}
		}

		.form-control-datepicker {
			background-color: var(--form-control-bg);
			border: 1px solid var(--border-color-secondary);
			font-family: 'Gotham-Book', sans-serif !important;
			padding: 0.5125rem 1rem;
			font-weight: 600;
			font-size: var(--fz-14px);
			line-height: 1.3125rem;
			color: var(--theme-dark-color);
			flex-direction: row-reverse;

			.k-input-inner {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 1.3125rem;
				color: var(--theme-dark-color);
				padding: 0 !important;
				padding-block: 0 !important;
				padding-inline: 0 !important;
			}

			.k-icon-button {
				background-color: transparent;
				border: 0;
				margin-right: 0.609375rem;
				padding: 0;
				width: 1rem;
				background-image: url(../images/calendar.svg);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				.k-i-calendar {
					opacity: 0;
				}
			}

			&.h-44px {
				height: 2.75rem;
			}
		}
		.datepicker-wrapper {
			position: relative;
			.close-button {
				&.clearBtn {
					position: absolute;
					top: 2px;
					right: 0.5rem;
					width: 2rem;
					height: calc(100% - 4px);
					display: inline-flex;
					align-items: center;
					justify-content: center;
					background-color: var(--form-control-bg);
					color: var(--theme-text-color-secondary);
					font-size: var(--fz-16px);
					z-index: 9;
				}
			}
			&.disabled {
				.close-button {
					&.clearBtn {
						background-color: var(--body-color) !important;
					}
				}
			}
		}
	}
}

.input-validation-error {
	border-color: var(--danger-lite-color) !important;

	&.form-control:focus {
		border-color: var(--danger-lite-color) !important;
	}
}

/*------------Default Form Styles End--------------*/

/*------------Checkbox Styles Start--------------*/
.k-checkbox {
	border: 1px solid var(--theme-text-color-secondary) !important;
	border-radius: 0.125rem !important;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
	border: 1px solid var(--success-color) !important;
	background-color: var(--success-color) !important;
}

.k-checkbox-md {
	width: 1rem !important;
	height: 1rem !important;
}

/*------------Checkbox Styles End--------------*/

/*------------Directiom Styles Start--------------*/
.direction-left {
	direction: ltr !important;
}

.direction-right {
	direction: rtl !important;
}

/*------------Directiom Styles End--------------*/

/*------------Scrollbar Styles Start--------------*/
.light-theme * {
	scrollbar-width: thin !important;
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track) !important;
}

.light-theme ::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0rem;
}

.light-theme ::-webkit-scrollbar-track {
	background-color: var(--scrollbar-track);
}

.light-theme ::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb);
	border-radius: 0rem;
	&:hover {
		background-color: var(--scrollbar-thumb-hover);
	}
}

.dark-theme * {
	scrollbar-width: thin !important;
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track) !important;
}
.dark-theme ::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0rem;
}

.dark-theme ::-webkit-scrollbar-track {
	background-color: var(--scrollbar-track);
}

.dark-theme ::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb);
	border-radius: 0rem;
	&:hover {
		background-color: var(--scrollbar-thumb-hover);
	}
}

/*------------Scrollbar Styles End--------------*/

/*------------Offcanvas Styles Start--------------*/

.default-offcanvas {
	height: 100%;

	&.width-452px {
		max-width: 28.25rem;
		width: 100% !important;
	}

	.offcanvas-header {
		position: absolute;
		right: 2.72rem;
		top: 2.35rem;
		padding: 0;
		z-index: 101;

		.btn-close {
			font-size: var(--fz-20px);
			color: var(--theme-dark-color);
			opacity: 1;
			padding: 0;
			background: none !important;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			&:before {
				position: absolute;
				content: '\e11b';
				font-family: 'WebComponentsIcons';
			}
		}
	}

	.canvas-title {
		font-size: var(--fz-22px);
		font-weight: 700;
		line-height: 2.0625rem;
		color: var(--theme-dark-color);
		padding: 1.4375rem 2.5rem 1.25rem 2.5rem;
		margin-bottom: 1.875rem;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			left: 2.5rem;
			bottom: 0;
			width: calc(100% - 4.5rem);
			height: 0.0625rem;
			background-color: var(--theme-canvas-title-border);
		}
		.info-icon {
			margin-left: 0.25rem;
		}
	}

	.offcanvas-body {
		overflow-y: visible;
	}
	.offcanvas-body,
	form {
		height: 100%;
		padding: 0;
		background-color: var(--offcanvas-body-bg);

		.default-form-style {
			height: calc(100% - 13.6875rem);
			overflow-y: scroll;
			width: calc(100% - 1rem);
			margin-bottom: 2rem;
			padding: 0 0.5rem 1.25rem 2.5rem;
			&.without-footer {
				height: calc(100% - 8.5rem);
			}

			.form-group .default-multi-select {
				min-height: 5.5625rem;
				height: auto;
			}
		}

		.default-offcanvas-footer {
			padding: 0 2rem 0 2.5rem;
			height: 5rem;
			display: flex;
			align-items: center;
			background-color: var(--body-color);
		}
	}
}

/*------------Offcanvas Styles End--------------*/

/*------------K-animation Containers Styles Start--------------*/

.k-animation-container {
	::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-thumb);
	}
	::-webkit-scrollbar-track {
		background-color: var(--scrollbar-track);
	}
	.k-multiselect-popup {
		background-color: var(--default-dropdown-bg-color);
		.k-list-ul {
			background-color: var(--default-dropdown-bg-color);
			.k-list-item {
				padding: 0 1.125rem;
				border: 0;

				.k-animation-container-select-box {
					min-height: 2.8125rem;
					display: flex;
					align-items: center;
					font-size: 0.875rem;
					color: var(--k-animation-link-color);
					font-weight: 600;
					border-bottom: 1px solid var(--multisect-border);
					width: 100%;

					input[type='checkbox'] {
						width: 0.875rem;
						height: 0.875rem;
						border: 1px solid var(--checkbox-uncheck-border);
						border-radius: 0.125rem;
						margin-right: 0.625rem;
						opacity: 0;
					}

					.custom-checkbox-label {
						position: relative;
						display: inline-flex;
						align-items: center;

						&:before {
							position: absolute;
							content: '';
							width: 0.875rem;
							height: 0.875rem;
							left: -1.5rem;
							border: 1px solid var(--checkbox-uncheck-border);
							border-radius: 0.125rem;
							margin-right: 0.625rem;
							background-color: var(--white-color);
						}
					}
				}

				&.k-selected,
				&:hover {
					background-color: var(--multiselect-hover) !important;

					.k-animation-container-select-box {
						color: var(--k-animation-link-color);

						.custom-checkbox-label {
							&:before {
								border: 1px solid var(--success-color);
								background-color: var(--success-color);
							}

							&:after {
								position: absolute;
								content: '';
								left: -1.2rem;
								/*top: 0.25rem;*/
								width: 0.325rem;
								height: 0.55rem;
								border: solid var(--white-color);
								border-width: 0 0.125rem 0.125rem 0;
								transform: rotate(45deg);
							}
						}
					}
				}
			}
		}
	}
}

.k-animation-container {
	.kendo-grid-filter-menu-container {
		.k-button-solid-primary {
			background-color: #1268b3 !important;
			border-color: #1268b3 !important;

			&:hover {
				background-color: #004b8d !important;
				border-color: #004b8d !important;
			}
		}
	}
}

.k-animation-container {
	.default-select-dropdown {
		background-color: var(--default-dropdown-bg-color);
		.k-searchbox {
			border: 1px solid var(--border-color-tertiary) !important;
			background-color: var(--form-control-bg);
			.k-input-inner {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 1.3125rem;
				color: var(--theme-dark-color);
			}
		}
		.k-list {
			background-color: var(--default-dropdown-bg-color);
		}
		.k-list-ul {
			border: 0 !important;
			.k-list-item {
				border: 0;
				padding: 0;
				.k-list-item-text {
					min-height: 2.8125rem;
					display: flex;
					align-items: center;
					font-size: 0.875rem;
					color: var(--k-animation-link-color);
					font-weight: 600;
					border-bottom: 1px solid var(--default-dropdown-border-color);
					width: 100%;
					padding: 0 1.125rem;
				}
				&.k-selected {
					background-color: rgba(
						var(--default-dropdown-selected-bg-color),
						0.15
					) !important;
					color: var(--theme-color) !important;
					.k-list-item-text {
						border-bottom: 0;
						color: var(--theme-color) !important;
					}
				}
				&:hover {
					background-color: var(--default-dropdown-bg-color) !important;
				}
			}
		}
	}
}

/*------------K-animation Containers Styles End--------------*/

/*------------Alert Modal Styles Start--------------*/

.alert-modal {
	.k-dialog {
		width: 100%;
		height: 100%;
		max-width: 40.75rem;
		max-height: 25.125rem;
		background: var(--white-color);
		border-radius: 0.625rem;

		.k-window-titlebar {
			position: absolute;
			right: 0.65rem;
			top: 1.275625rem;
			padding: 0;
			z-index: 9;
			border: 0;

			.k-window-title {
				display: none;
			}

			.k-window-titlebar-actions {
				border: 0 !important;
				padding: 0 !important;
				outline: none !important;
				box-shadow: none !important;
				background: var(--white-color) !important;

				.k-button {
					border: 0 !important;
					padding: 0 !important;
					outline: none !important;
					box-shadow: none !important;
					background: var(--white-color) !important;

					&:after {
						display: none !important;
					}
				}

				.k-icon.k-i-x.k-button-icon {
					border: 0 !important;
					font-size: var(--fz-20px);
					box-shadow: none !important;
					outline: 0 none !important;
					background: var(--theme-light-color) !important;
					color: var(--theme-dark-color);
				}
			}
		}

		.k-window-content {
			padding: 0;

			.modal-content {
				justify-content: space-between;
				height: 100%;

				.modal-body-content {
					padding: 1.25rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: calc(100% - 5rem);
					background-color: var(--theme-light-color);
					border-radius: 0.625rem 0.625rem 0 0;
					img {
						width: 9rem;
					}

					h4 {
						font-size: var(--fz-36px);
						line-height: 2.9375rem;
						color: var(--theme-dark-color);
						font-weight: 700;
						text-align: center;
					}

					p {
						font-size: var(--fz-14px);
						line-height: 1.3125rem;
						text-align: center;
						color: var(--theme-text-color-primary);
						max-width: 28.9375rem;
						margin: 0 auto;
					}
					.modal-highlight {
						font-weight: 700;
						margin-top: 0.75rem;
					}
				}
			}

			.k-window-actions {
				border: 0;
				height: 5rem;
				display: flex;
				justify-content: flex-end;
				background: var(--body-color);
				border-radius: 0 0 0.625rem 0.625rem;

				.k-button {
					max-width: 10rem;
				}
			}
		}
	}
	&.base-modal {
		.k-dialog {
			max-width: 50.75rem;
		}
		.k-window-title {
			display: block !important;
		}
		.k-window-titlebar.k-dialog-titlebar {
			width: calc(100% - 2rem);
			background-color: transparent !important;
			font-size: var(--fz-22px);
			font-weight: 700;
			line-height: 2.0625rem;
			color: var(--theme-dark-color);
		}
		.modal-scroll {
			overflow-y: auto;
			padding-right: 1rem;
		}
		.modal-body-content {
			padding-top: 5rem !important;
			justify-content: unset !important;
		}
	}
	&.cropper{
		.k-dialog{
			max-width: 75%;
    		max-height: 75%;
			.k-dialog-content{
				height: 100% !important;
				overflow: hidden;
				border-top-right-radius: 0.625rem;
				border-top-left-radius: 0.625rem;
				& > div{
					height: 100% !important;
					& + div{
						display: none;
					}
				}
				.cropper-container{
					width: 100% !important;
					height: 100% !important;
				}
			}
			.k-actions{
				justify-content: flex-end;
				.k-button {
					max-width: 10rem;
				}
			}
		}
	}
	&.with-title{
		.k-dialog .k-window-titlebar {
			position: relative;
			right: unset;
			top: unset;
			padding: 1.25rem;
			width: 100%;
			font-size: var(--fz-22px);
			&:after {
				position: absolute;
				content: "";
				left: 1.25rem;
				bottom: 0;
				width: calc(100% - 2.5rem);
				height: 0.0625rem;
				background-color: var(--theme-canvas-title-border);
			}
		}
		.k-window-content {
			overflow: unset;
		}
		.modal-body-content{
			padding-top: 1rem  !important;
			height: 100% !important;
		}
	}
}

/*------------Alert Modal Styles End--------------*/
/*=========== Confirmation alert modal start ============*/
.confirmation {
	.k-dialog {
		max-width: 32rem;
		max-height: 25rem;
	}
	&.question-modal{
		.k-dialog {
			max-width: 40.75rem;
    		max-height: 25.125rem;
			.form-group{
				padding-bottom:1rem !important;
			}
			.bg-highlight-wrapper{
				padding: 0.5rem;
				border-radius: 0.438rem;
				background-color: rgba(var(--info-color-rgb), 0.15);
				.bg-highlight{
					font-size: var(--fz-12px) !important;
					font-weight: 700;
				}
			}
			
		}
	}

	.modal-body-content {
		padding: 1.7rem !important;

		p {
			line-height: unset !important;
		}
	}

	.k-window-actions {
		padding-inline: 1.7rem !important;
	}

	.default-form-style {
		.form-control.text-area {
			height: 5rem;
			resize: none;
		}
	}

	.show-password-input {
		padding-right: 2.25rem;
	}

	.show-password {
		position: absolute;
		right: 0.5rem;
		bottom: 2.15rem;
		color: var(--input-text-color);
	}
}

/*=========== Confirmation alert modal end ============*/

/*===============Custom Checkbox design Start =============*/
.custom-checkbox-list {
	margin: 0.5rem 0;

	.k-checkbox {
		border: 1px solid var(--grid-pg-btn-color) !important;
	}
	.k-checkbox:checked {
		border: 1px solid var(--success-color) !important;
	}

	.k-checkbox-label {
		font-weight: 600;
		font-size: var(--fz-14px);
		line-height: 100%;
		color: var(--k-animation-link-color);
		margin-inline-start: 0.625rem !important;
	}
	&.sso-checkbox-wrapper {
		display: flex;
		flex-wrap: wrap;
		background-color: var(--profile-details-active-color);
		height: 100%;
		margin: 0;
		border-radius: 0.375rem;
		justify-content: center;
		align-items: center;
		padding: 1.5rem 1rem;
		span {
			width: 25%;
			display: flex;
			align-items: center;
		}
		.k-disabled.undefined.disabled {
			pointer-events: unset !important;
			.k-checkbox-label {
				cursor: not-allowed !important;
				pointer-events: unset !important;
			}
		}
		.k-checkbox:disabled,
		.k-checkbox.k-disabled {
			cursor: not-allowed !important;
			pointer-events: unset !important;
		}
	}
}
.role-cb-list {
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 0;
	margin-bottom: 0.1875rem;
	> span {
		position: relative;
		.k-checkbox {
			right: 0;
			position: absolute;
			top: 2px;
		}
		.k-checkbox-label {
			padding-right: 1.625rem !important;
			margin-inline-start: 0 !important;
		}
	}
}
/*===============Custom Checkbox design End =============*/

/*===============custom radio design Start =============*/

[type='radio']:checked,
[type='radio']:not(:checked) {
	opacity: 0;
	width: 16px;
	height: 16px;
}

[type='radio'] + label {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 0.5rem;
	cursor: pointer;

	.check-outer {
		position: absolute;
		left: -1rem;
		width: 16px;
		height: 16px;
		min-width: 16px;
		border-radius: 50%;
		background: transparent;
		border: 1px solid var(--border-color-secondary) !important;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		.check-inner {
			width: 10px;
			height: 10px;
			min-width: 10px;
			border-radius: 50%;
			background: transparent;
		}
	}
}

[type='radio']:checked + label {
	.check-outer {
		border: 1px solid var(--success-color) !important;

		.check-inner {
			background: var(--success-color) !important;
		}
	}
}

/*===============custom radio design end =============*/

/*===============Input form type custom radio design Start =============*/

.form-control-radio-btn {
	position: relative;
	height: 2.75rem;
	display: flex;

	[type='radio']:checked,
	[type='radio']:not(:checked) {
		opacity: 0;
		position: absolute;
		width: 16px;
		height: 16px;
		top: 0.8125rem;
		left: 0.8125rem;
	}

	[type='radio'] + label {
		border: 1px solid rgba(var(--border-color-secondary-rgb), 0.7) !important;
		background-color: rgba(var(--light-color-rgb), 0.3) !important;
		height: 2.75rem;
		width: 100%;
		margin: 0 !important;
		border-radius: 0.3125rem;
		padding: 0.8125rem 0.8125rem 0.8125rem 2.8125rem;
		font-weight: 600;
		color: var(--theme-text-color-secondary);
	}

	[type='radio'] + label .check-outer {
		left: 0.8125rem;

		.check-inner {
			background: var(--disabled-color) !important;
		}
	}

	[type='radio']:checked + label {
		border-color: var(--theme-color) !important;
		background-color: rgba(var(--theme-color-rgb), 0.09) !important;

		.check-outer {
			border: 1px solid var(--theme-color) !important;

			.check-inner {
				background: var(--theme-color) !important;
			}
		}
	}

	&.me-0 {
		.me-3 {
			margin-right: 0 !important;
		}
	}

	[type='radio'].disabled {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		cursor: not-allowed;
		z-index: 10;
	}
	[type='radio'].disabled + label {
		cursor: not-allowed;
		opacity: 0.4;
	}
}
.dark-theme {
	.form-control-radio-btn {
		[type='radio'] + label .check-outer {
			border: 1px solid var(--theme-text-color-secondary) !important;
			.check-inner {
				background: var(--theme-text-color-secondary) !important;
			}
		}
		[type='radio']:checked + label {
			border-color: var(--theme-color) !important;
			background-color: rgba(var(--theme-color-rgb), 0.09) !important;

			.check-outer {
				border: 1px solid var(--theme-color) !important;

				.check-inner {
					background: var(--theme-color) !important;
				}
			}
		}
	}
}

/*===============Input form type custom radio design End =============*/

/*===============custom Toggle design Start =============*/
.k-switch-on .k-switch-track {
	border-color: var(--success-color) !important;
	background-color: var(--success-color) !important;
}

/*===============custom Toggle design End =============*/

/*===============Input form type custom Toggle design Start =============*/
.form-control-switch-btn {
	padding: 0.8125rem 0.8125rem 0.8125rem 0.8125rem;
	border: 1px solid rgba(var(--border-color-secondary-rgb), 0.7) !important;
	background-color: rgba(var(--light-color-rgb), 0.3) !important;
	border-radius: 0.3125rem;
	height: 4.35rem;
	display: flex;
	align-items: center;

	.k-switch {
		width: 36px;
		height: 18px;

		.k-switch-track {
			width: 36px;
			height: 18px;
		}

		.k-switch-thumb {
			width: 16px;
			height: 16px;
		}
	}

	.k-switch-off {
		.k-switch-track {
			border-color: var(--disabled-color) !important;
			background: var(--disabled-color) !important;
		}

		.k-switch-thumb-wrap {
			left: 9px !important;
		}
	}

	.k-switch-on {
		.k-switch-thumb-wrap {
			left: calc(100% - 9px) !important;
		}
	}
}

/*===============Input form type custom Toggle design End =============*/

/*===============custom Kendo sort design Start =============*/
.k-sort-icon {
	color: var(--success-color) !important;
}

/*===============custom Kendo sort design End =============*/

/*======== Toastify Design start=======*/
.custom-toastify-container {
	position: fixed !important;
	top: 1rem !important;
	right: 0rem !important;
	max-width: 23.25rem;
	min-height: 3.9375rem;
	width: 100%;
	height: auto;
	border-radius: 0 !important;
	border-top-left-radius: 0.3125rem !important;
	border-bottom-left-radius: 0.3125rem !important;
	padding: 1.125rem 1.375rem 1.063rem 1.313rem !important;
	border: unset !important;
	display: flex !important;
	align-items: center;
	.k-child-animation-container {
		width: 100%;
	}
	.k-notification-container {
		width: 100%;
		.k-notification-error,
		.k-notification-success,
		.k-notification-warning {
			width: 100%;
			border: unset !important;
			padding: 0 !important;
		}
	}
	.k-i-x-outline,
	.k-i-check-outline,
	.k-i-exclamation-circle {
		&.k-notification-status {
			width: 2.625rem !important;
			height: 2.625rem !important;

			&::before {
				content: '';
				position: absolute;
				top: 0px;
				left: 0px;
				width: 2.625rem !important;
				height: 2.625rem !important;
				background-size: contain !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
			}
		}
	}
	.toast-wrap {
		display: flex;
		flex-direction: column;
		padding-left: 1.4rem;

		.invalid-head {
			font-weight: 600;
			font-size: var(--fz-16px);
			line-height: 1.5rem;
		}

		.invalid-text {
			font-weight: 500;
			font-size: var(--fz-12px);
			line-height: 1rem;
			color: var(--toast-msg-color);
			max-width: 15rem;
			height: auto;
		}
	}
	&.error-wrapper {
		background-color: var(--warning-lite-color) !important;
		color: var(--danger-lite-color) !important;
		.k-notification-error {
			background-color: var(--warning-lite-color) !important;
			color: var(--danger-lite-color) !important;
		}
		.invalid-head {
			color: var(--toastify-warning-color);
		}
		.k-i-x-outline {
			&.k-notification-status {
				&::before {
					background-image: url(../images/warning-toast.png) !important;
				}
			}
		}
	}
	&.success-wrapper {
		background-color: var(--success-light-color) !important;
		color: var(--success-dark-color) !important;
		.k-notification-success {
			background-color: var(--success-light-color) !important;
			color: var(--success-dark-color) !important;
		}
		.invalid-head {
			color: var(--success-dark-color);
		}
		.k-i-check-outline {
			&.k-notification-status {
				&::before {
					background-image: url(../images/success-icon.png) !important;
				}
			}
		}
	}

	&.warning-wrapper {
		background-color: var(--warning-lite-color) !important;
		color: var(--warning-color) !important;
		.k-notification-warning {
			background-color: var(--warning-lite-color) !important;
			color: var(--warning-color) !important;
		}
		.invalid-head {
			color: var(--warning-color);
		}
		.k-i-exclamation-circle {
			&.k-notification-status {
				&::before {
					background-image: url(../images/warning-alert-icon.png) !important;
				}
			}
		}
	}
}

.k-animation-container.custom-toastify-container {
	z-index: 1000 !important;
}
.k-overlay,
.k-dialog-wrapper {
	z-index: 114 !important;
}


/*======== Toastify Design End =======*/

/*=====custom cropper start =====*/
.alert-modal.light-theme.cropper {
	.cropper-modal {
		background-color: var(--white-color) !important;
	}

	.k-dialog-actions {
		justify-content: flex-end;
	}

	.k-actions-stretched > * {
		flex: unset;
		min-width: 10rem;
	}
}

/*=====custom cropper end =====*/

/*-----Kendo custom Loader login Start ----*/
.k-loader-primary {
	color: var(--white-color) !important;
}

/*-----Kendo custom Loader login End ----*/

/*-----Skeleton Style Start ----*/
.btn-skeleton {
	min-width: 12.25rem;
	text-align: center;
	border-radius: 0.125rem;
	background: rgba(130, 130, 130, 0.2);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(8%, rgba(130, 130, 130, 0.2)),
		color-stop(18%, rgba(130, 130, 130, 0.3)),
		color-stop(33%, rgba(130, 130, 130, 0.2))
	);
	background: linear-gradient(
		to right,
		rgba(130, 130, 130, 0.2) 8%,
		rgba(130, 130, 130, 0.3) 18%,
		rgba(130, 130, 130, 0.2) 33%
	);
	background-size: 800px 100px;
	animation: wave-lines 2s infinite ease-out;
	border: 0;
	height: 2.75rem;
	border-radius: 0.375rem !important;
}
.dummy-skelton {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0rem 1rem 2rem 1rem;
	&.l4skeleton {
		height: calc(100% - 4.125rem);
	}
	&.tenant-skeleton-wrap {
		height: calc(100% - 5rem);
		justify-content: unset;
	}
	&.failedDropSkeleton {
		padding: 1rem 0rem 0rem 0rem;
	}
	&.h-85 {
		height: 85%;
	}
	&.settings-skeleton {
		padding-top: 2rem;
	}
	&.sso-skeleton {
		height: calc(100% - 4.125rem);
		// .pd-sso{
		// 	padding-top:2.298rem;
		// }
		.skeleton .line.product-line {
			margin-bottom: 3rem !important;
		}
	}
	&.p-top {
		padding: 3rem 1rem 2rem 1rem;
	}
	&.pad-connect {
		padding: 1.7rem 0 0 0;
	}

	.box {
		width: 100%;
		padding: 0 0.938rem;
		height: 100%;
		// margin-top: 2rem;
	}

	.skeleton {
		padding: 2rem;
		width: 100%;
		background: #fff;
		// margin-bottom: 1.25rem;
		border-radius: 0.313rem;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
			0 1px 8px 0 rgba(0, 0, 0, 0.12);
		height: 100%;
		overflow: hidden;
		&.audit-trial-skeleton {
			height: 6.25rem;
		}
		&.tenant-box {
			min-height: 14.75rem;
		}

		&.audit-square {
			height: calc(100% - 9.625rem);
			padding-top: 0;
			.square {
				width: 100%;
			}
		}
		&.batch-detail-skeleton {
			height: 11.25rem;
		}
		&.batch-detail-square {
			height: 20rem;
			padding-top: 0;
			.square {
				width: 100%;
			}
		}
	}

	.skeleton .square {
		height: 5rem;
		border-radius: 0.313rem;
		background: rgba(130, 130, 130, 0.2);
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			color-stop(8%, rgba(130, 130, 130, 0.2)),
			color-stop(18%, rgba(130, 130, 130, 0.3)),
			color-stop(33%, rgba(130, 130, 130, 0.2))
		);
		background: linear-gradient(
			to right,
			rgba(130, 130, 130, 0.2) 8%,
			rgba(130, 130, 130, 0.3) 18%,
			rgba(130, 130, 130, 0.2) 33%
		);
		background-size: 800px 100px;
		// animation: wave-squares 2s infinite ease-out;
		height: 100%;
		animation: wave-lines 2s infinite ease-out;
		&.opacity-3 {
			opacity: 0.3;
		}
	}

	.skeleton .line {
		height: 0.75rem;
		margin-bottom: 0.688rem;
		border-radius: 0.125rem;
		background: rgba(130, 130, 130, 0.2);
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			color-stop(8%, rgba(130, 130, 130, 0.2)),
			color-stop(18%, rgba(130, 130, 130, 0.3)),
			color-stop(33%, rgba(130, 130, 130, 0.2))
		);
		background: linear-gradient(
			to right,
			rgba(130, 130, 130, 0.2) 8%,
			rgba(130, 130, 130, 0.3) 18%,
			rgba(130, 130, 130, 0.2) 33%
		);
		background-size: 800px 100px;
		animation: wave-lines 2s infinite ease-out;
		&.product-line {
			height: 2.875rem;
			margin-bottom: 1.5625rem;
			border-radius: 0.3125rem;
			&.file-failed {
				height: 4.688rem;
				margin-bottom: 0.625rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&.h-16 {
				height: 16rem;
			}
		}
		&.manufacturer-line {
			height: 6.721rem;
			margin-bottom: 1.5625rem;
			border-radius: 0.3125rem;
		}
		&.manufacturer-box {
			height: 12.5rem;
			margin-bottom: 1.5625rem !important;
			border-radius: 0.3125rem;
		}
		&.connection-type {
			height: 2.75rem;
			margin-bottom: 1.5625rem;
			border-radius: 0.3125rem;
		}
	}

	.skeleton .circle {
		border-radius: 50% !important;
		height: 8rem !important;
		width: 100%;
		min-width: 8rem;
		background: linear-gradient(
			to right,
			rgba(130, 130, 130, 0.2) 8%,
			rgba(130, 130, 130, 0.3) 18%,
			rgba(130, 130, 130, 0.2) 33%
		);
		background-size: 800px 100px;
		animation: wave-squares 2s infinite ease-out;
	}

	.skeleton .reset-square {
		height: 5rem;
		border-radius: 0.313rem;
		background: rgba(130, 130, 130, 0.2);
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			color-stop(8%, rgba(130, 130, 130, 0.2)),
			color-stop(18%, rgba(130, 130, 130, 0.3)),
			color-stop(33%, rgba(130, 130, 130, 0.2))
		);
		background: linear-gradient(
			to right,
			rgba(130, 130, 130, 0.2) 8%,
			rgba(130, 130, 130, 0.3) 18%,
			rgba(130, 130, 130, 0.2) 33%
		);
		background-size: 800px 100px;
		animation: wave-squares 2s infinite ease-out;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.skeleton .reset-line {
		// margin-bottom: 2.5rem !important;
		border-radius: 0.125rem;
		background: rgba(130, 130, 130, 0.2);
		background: linear-gradient(
			to right,
			rgba(130, 130, 130, 0.2) 8%,
			rgba(130, 130, 130, 0.3) 18%,
			rgba(130, 130, 130, 0.2) 33%
		);
		background-size: 800px 100px;
		animation: wave-lines 2s infinite ease-out;
		margin: 0 auto;
	}

	.skeleton-right {
		flex: 1;
	}

	.skeleton-left {
		&.circle-left {
			justify-content: center;
			align-items: center;
			display: flex;
		}
	}

	.skeleton-left {
		flex: 2;
		padding-right: 2rem;
		height: 100%;
	}

	.flex1 {
		flex: 1;
	}
	.flex4 {
		flex: 4 1;
	}
	.flex2 {
		flex: 3;
	}

	.skeleton .line:last-child {
		margin-bottom: 0;
	}
	.right-flex-wrap {
		display: flex;
		width: 50%;
		flex-direction: column;
	}
	.h-86 {
		height: calc(100% - 12rem) !important;
	}
	.h30 {
		height: 1.875rem !important;
	}
	.h-30 {
		height: 14rem !important;
	}
	.h7 {
		min-height: 7.563rem !important;
		height: 100%;
	}
	.h70 {
		height: 4.375rem !important;
	}
	.h-34 {
		height: calc(100% - 4rem) !important;
	}
	.h46 {
		height: 2.875rem !important;
	}
	.h-47px{
		height: 4.75rem !important;
	}
	.h-5 {
		height: 4.922rem !important;
	}
	.h-7 {
		height: 7.3rem !important;
	}
	.h-8 {
		height: 8.5rem !important;
	}

	.h12 {
		height: 0.75rem !important;
	}

	.h24 {
		height: 1.5rem !important;
	}
	.w-16 {
		width: 16% !important;
	}
	.w-24 {
		width: 24.5% !important;
	}
	.w40 {
		width: 40% !important;
	}

	.w50 {
		width: 50% !important;
	}

	.w75 {
		width: 75% !important;
	}
	.w-173 {
		width: 173% !important;
	}
	.w-150 {
		width: 150% !important;
	}
	.w-160 {
		width: 160% !important;
	}
	.w-200 {
		width: calc(200% + 2rem) !important;
	}
	.mtop-4 {
		margin-top: 4.3rem !important;
	}
	.mleft-11 {
		margin-left: 11rem !important;
	}
	.mleft-12 {
		margin-left: -60% !important;
	}
	.m15 {
		margin-bottom: 0.938rem !important;
	}

	.mtop-15 {
		margin-top: 1.876rem;
	}

	.mbm-25 {
		margin-bottom: 2.5rem !important;
	}
	.bg-none {
		background: none !important;
	}
}

@keyframes wave-lines {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes wave-squares {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.product-skeleton {
	padding: 2rem !important;
	background-color: #fff;
	border-radius: 0.313rem;
	margin-bottom: 0.5rem;
}
.w-33 {
	width: 33%;
}
.w-50 {
	width: 50%;
}
.w-31 {
	width: 31rem;
}
.w-60 {
	width: 60rem;
}
.h-7 {
	height: 7.5rem !important;
}
.h25 {
	height: 25rem !important;
}
.gs-btn {
	width: 8rem;
	position: absolute;
	right: 0rem;
	bottom: 0rem;
}
.product-breadcrumbs {
	padding: 2rem;
	margin: 0;
	display: flex;
	overflow: hidden;
	box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	list-style-type: none;
	min-height: 8rem;
	background: rgba(130, 130, 130, 0.2);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(8%, rgba(130, 130, 130, 0.2)),
		color-stop(18%, rgba(130, 130, 130, 0.3)),
		color-stop(33%, rgba(130, 130, 130, 0.2))
	);
	background: linear-gradient(
		to right,
		rgba(130, 130, 130, 0.2) 8%,
		rgba(130, 130, 130, 0.3) 18%,
		rgba(130, 130, 130, 0.2) 33%
	);
	background-size: 800px 100px;
	animation: wave-squares 2s infinite ease-out;
}
.weblink-skeleton {
	width: 30%;
	padding: 0;
	height: auto;
	background-color: var(--border-color);
	opacity: 0.6;
	.box {
		padding: 0;
		.skeleton {
			background-color: var(--border-color);
			opacity: 0.6;

			.skeleton-left {
				padding-right: 0;
				.product-line {
					height: 2.5rem;
					margin-bottom: 1.2625rem;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.offcanvas-wrap {
	padding: 7rem 1rem 2rem 1rem !important;
	.line {
		&:last-child {
			height: 6rem !important;
		}
	}
}
.product-line {
	&.module-height {
		height: 3.5rem !important;
	}
}
.dark-theme {
	.dummy-skelton {
		.skeleton,
		.product-skeleton {
			background: #31373e !important;
			.line {
				background: linear-gradient(
					to right,
					rgba(25, 31, 40, 0.8) 8%,
					rgba(25, 31, 40, 0.8) 18%,
					rgba(25, 31, 40, 0.6) 33%
				) !important;
			}
		}
	}
}
.graphSkeleton-wrap {
	min-height: 20rem;
	height: 100%;
	.graph-skeleton {
		.skeleton-left {
			display: flex;
			flex-direction: row;
			gap: 3rem;
			align-items: flex-end;
			.product-line {
				width: 2.87rem;
				height: 10rem;
				margin-bottom: 0;
				&.h1 {
					height: 15rem;
				}
				&.h2 {
					height: 10rem;
				}
				&.h3 {
					height: 4rem;
				}
				&.h4 {
					height: 12rem;
				}
			}
		}
	}
}
/*-----Skeleton style End ----*/

.k-animation-container {
	.custom-dropdown {
		padding: 0.5rem;
		padding-top: 0.75rem;
		width: 15.25rem;
		border: 0;
		background-color: transparent;
		position: absolute;
		border-radius: 0.471398125rem;

		.k-menu-group {
			position: relative;
			width: 100%;
			border-radius: 0.471398125rem;
			box-shadow: 0px 0px 0.75rem rgba(0, 0, 0, 0.1) !important;
			position: relative;
			z-index: 10;

			&:after {
				content: '';
				position: absolute;
				top: -0.5rem;
				right: 2rem;
				width: 1.0625rem;
				height: 1.0625rem;
				border-radius: 0.1875rem;
				box-shadow: 0px 0px 0.75rem rgba(0, 0, 0, 0.1) !important;
				transform: rotate(45deg);
				background-color: var(--default-dropdown-bg-color);
				z-index: 1;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0rem;
				right: 1.5rem;
				width: 2.0625rem;
				height: 1.0625rem;
				background-color: var(--default-dropdown-bg-color);
				z-index: 10;
			}

			li {
				border-bottom: 0.125rem solid var(--profile-dropdown-border);
				position: relative;

				&:first-child {
					border-top-right-radius: 0.471398125rem;
					border-top-left-radius: 0.471398125rem;

					.k-link {
						border-top-right-radius: 0.471398125rem;
						border-top-left-radius: 0.471398125rem;
					}
				}

				&:last-child {
					border-bottom-right-radius: 0.471398125rem;
					border-bottom-left-radius: 0.471398125rem;
					border-bottom: 0;

					.k-link {
						border-bottom-right-radius: 0.471398125rem;
						border-bottom-left-radius: 0.471398125rem;
						border-bottom: 0;
					}
				}

				&:hover {
					background-color: var(--default-dropdown-bg-color) !important;
				}

				.k-link {
					padding: 0;
					font-size: 0.875rem;
					line-height: 0.875rem;
					color: var(--k-animation-link-color);
					font-weight: 600;
					width: 100%;
					background-color: var(--default-dropdown-bg-color) !important;

					.click-span {
						padding: 0.75rem 1rem;
						width: 100%;
					}

					&.active,
					&:active {
						background-color: var(--default-dropdown-bg-color) !important;
					}
				}

				&.active,
				&:active {
					background-color: var(--default-dropdown-bg-color) !important;
				}
			}
		}
		.form-control-switch-btn {
			border: 0 !important;
			.k-switch-off {
				.k-switch-track {
					border-color: #b7b7b7 !important;
					background: #b7b7b7 !important;
				}
			}
			.k-switch-on .k-switch-track {
				border-color: #34ad4d !important;
				color: #fff;
				background-color: #34ad4d !important;
			}
		}

		&.profile-dropdown {
			right: 0.85rem;
			min-width: 15.25rem;
			position: relative;
			.k-menu-group {
				background-color: var(--kendo-group-bg-color);
				&:after,
				&:before {
					right: calc(50% - 0.5rem);
				}
			}
		}
		&.header-no-logo {
			.k-menu-group {
				&:after,
				&:before {
					right: calc(50% - 5.8rem);
				}
			}
		}
	}
	.k-tooltip {
		max-width: 16rem;
		font-size: 0.875rem;
	}
}

.ellipsis-dropdown {
	.dropdown-menu {
		box-shadow: 0px 0px 12.0678px rgba(0, 0, 0, 0.1) !important;
		border: 0;
		width: 8.0625rem;
		min-width: unset;
		padding: 0;
		border-radius: 0.471398125rem;
		background-color: var(--kendo-group-bg-color) !important;

		.dropdown-item {
			padding: 0;
			border-bottom: 0.125rem solid var(--profile-dropdown-border);
			background-color: var(--kendo-group-bg-color) !important;
			button {
				background-color: transparent;
				box-shadow: none !important;
				outline: none !important;
				border: 0 !important;
				padding: 0.75rem 1rem;
				width: 100%;
				font-size: var(--fz-14px);
				font-weight: 600;
				line-height: 0.875rem;
				color: var(--theme-text-color-secondary);
				background-color: var(--kendo-group-bg-color) !important;
				text-align: left;

				&:hover {
					background-color: var(--kendo-group-bg-color) !important;
				}
			}
			.custom-action-dropdown {
				word-break: break-word;
				white-space: normal;
				line-height: 1.2rem;
			}

			&:first-child {
				border-top-right-radius: 0.471398125rem;
				border-top-left-radius: 0.471398125rem;

				button {
					border-top-right-radius: 0.471398125rem;
					border-top-left-radius: 0.471398125rem;
				}
			}

			&:last-child {
				border-bottom-right-radius: 0.471398125rem;
				border-bottom-left-radius: 0.471398125rem;
				border-bottom: 0;

				button {
					border-bottom-right-radius: 0.471398125rem;
					border-bottom-left-radius: 0.471398125rem;
					border-bottom: 0;
				}
			}

			&.active,
			&:active {
				background-color: transparent !important;
			}
		}
	}

	.dropdown-toggle.btn.btn-primary {
		background-color: transparent !important;
		border: 0 !important;
		color: var(--dark-color) !important;

		&:after {
			display: none;
		}
	}
}
.with-loader {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	.loader-primary {
		&.link-loader {
			display: inline-flex;
			width: auto;
			.k-loader-pulsing-2.k-loader-sm .k-loader-canvas {
				width: 16px;
				height: 6px;
			}
			.k-loader-sm .k-loader-segment {
				width: 5px;
				height: 5px;
			}
		}
	}
}
.acg-version {
	position: absolute;
	bottom: 0rem;
	left: 0;
	z-index: 111;
	padding: 0.25rem;
	font-weight: 500;
	font-size: var(--fz-12px);
	line-height: 150%;
	background-color: var(--primary-color);
	color: var(--white-color);
	width: 5.25rem;
	text-align: center;
	margin-bottom: 0;
}

.k-datepicker-popup {
	font-size: 0.875rem !important;
	border-color: var(--border-color-secondary) !important;

	.k-calendar {
		font-size: 0.875rem !important;
		color: var(--theme-dark-color);
		background-color: var(--theme-light-color) !important;
		.k-calendar-th,
		.k-calendar-caption,
		.k-meta-header,
		.k-month-header {
			color: var(--theme-dark-color);
		}

		.k-calendar-navigation {
			width: 4rem !important;
			background-color: var(--body-color) !important;
			&::before,
			&::after {
				box-shadow: 0 0 6em 3em var(--theme-light-color) !important;
			}
		}

		.k-calendar-header {
			margin-left: 0 !important;
			margin-right: 0 !important;
			padding-inline: 0 !important;
		}

		.k-calendar-view {
			padding-inline: 0.3rem !important;
			width: 19rem !important;
			background-color: var(--theme-light-color) !important;
			&::after {
				box-shadow: 0 0 32px 16px var(--theme-light-color) !important;
			}
			.k-content {
				background-color: var(--theme-light-color) !important;
				color: var(--theme-dark-color);
				.k-reset {
					li {
						color: var(--theme-dark-color);
					}
				}
			}
		}

		.k-content {
			.k-reset {
				li {
					color: var(--theme-dark-color);
				}
			}
		}

		.k-calendar-table {
			margin-inline: 0 !important;
			width: 100% !important;
		}
		.k-calendar-navigation-highlight {
			background-color: rgba(var(--warning-color-rgba), 0.15) !important;
		}
	}
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
	background-color: rgba(var(--warning-color-rgba), 0.15) !important;
	color: var(--theme-color) !important;
}

/*=======Grid no data design start=====*/
.k-grid-norecords {
	.k-table-td {
		height: calc(100vh - 31rem) !important;
	}
}

/*=======Grid no data design end=====*/
/*======kendo panelbar design starts here===*/
.k-panelbar,
.k-link {
	.k-i-chevron-down,
	.k-i-chevron-up {
		border: 1px solid var(--accordian-icon);
		border-radius: 50%;
		font-size: var(--fz-14px);
		line-height: 1.25rem;
		width: 1.25rem;
		height: 1.25rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
	&.k-selected {
		background-color: unset !important;
		color: var(--black-color) !important;
	}
}

/*======kendo panelbar design ends here===*/

/*======panel edit-icon design starts here===*/
.edit-icon-btn {
	position: absolute !important;
	right: 2.5rem;
	bottom: 1.5rem;
	background-color: unset !important;
	background-image: none !important;
	border: 0 !important;
}

/*======panel edit-icon design ends here===*/
.default-select-dropdown {
	.k-list-optionlabel {
		min-height: 2.8125rem;
		display: flex;
		align-items: center;
		font-size: 0.875rem;
		color: var(-theme-text-color-secondary);
		font-weight: 600;
		border-bottom: 1px solid var(--default-dropdown-border-color);
		width: 100%;
		padding: 0 1.125rem !important;
		gap: unset !important;
		&:hover {
			background-color: unset !important;
		}
		&.k-selected {
			border-bottom: 0;
			color: #e7643b !important;
			background-color: rgba(251, 149, 117, 0.15) !important;
			&:hover {
				background-color: #fff !important;
			}
		}
	}
}
.k-popup {
	background-color: var(--filter-popup) !important;
	.k-list {
		background-color: var(--filter-popup) !important;
		.k-list-item {
			color: var(--theme-dark-color);
			font-family: 'Gotham-Medium', sans-serif !important;
			font-weight: 600;
			font-size: var(--fz-12px);
			&.k-selected {
				color: var(--theme-color);
			}
		}
	}
	&.profile-dropdown {
		background-color: transparent !important;
	}

	.k-columnmenu-item {
		color: var(--k-animation-link-color);

		&:hover {
			background-color: var(--filter-popup) !important;
			color: var(--k-animation-link-color);
		}
	}

	.k-filter-menu-container {
		.k-picker-solid {
			background-color: var(--form-control-bg) !important;
			border: 1px solid var(--border-color-secondary) !important;

			&:hover {
				background-color: var(--form-control-bg) !important;
				border: 1px solid var(--border-color-secondary) !important;
			}
		}

		.k-input {
			background-color: var(--form-control-bg) !important;
			border: 1px solid var(--border-color-secondary) !important;
			color: var(--grid-body-text-color);
		}
		.k-input-inner {
			.k-input-value-text {
				color: var(--grid-body-text-color);
			}
		}
	}
}
/*=========Weblink design starts here=======*/
.custom-weblink-container {
	max-height: calc(100% - 7.5rem);
	overflow-y: auto;
	.margin-gutter {
		margin-bottom: 1rem;
	}
	.weblink-wrapper {
		height: 100%;
		margin-bottom: 1rem;
	}
}
.weblink-wrapper {
	list-style-type: none;
	padding-left: 0;
	background-color: var(--body-color);
	border: 1px solid var(--grid-pg-btn-border);
	border-radius: 0.313rem;
	// max-width: 20.938rem;
	padding: 0.5125rem 1rem;
	li {
		color: var(--theme-text-color-secondary);
		font-size: var(--fz-14px);
		line-height: 150%;
		font-weight: 400;
		padding: 0.15rem 0rem;
		display: flex;
		.link-head {
			font-weight: 700;
			min-width: 11.5rem;
		}
		.link-separator {
			font-weight: 700;
		}
		.link-data {
			word-break: break-word;
		}
	}
}

.cmn-weblink {
	font-size: var(--fz-14px);
	line-height: 150%;
	font-weight: 700;
	padding: 0.25rem 0.5rem;
	&.weblink-invalid {
		color: var(--danger-lite-color);
	}
	&.weblink-valid {
		color: var(--success-color);
	}
}
/*=========Weblink design ends here=======*/

/*---------------Date Range Picker Styles Start---------------*/

.k-daterangepicker-popup {
	background-color: var(--theme-light-color) !important;
	border-color: var(--border-color-secondary) !important;
	.k-calendar {
		background-color: var(--theme-light-color) !important;
		color: var(--theme-dark-color);
		.k-content {
			background-color: var(--theme-light-color) !important;
			color: var(--theme-dark-color);
		}
		.k-calendar-th,
		.k-calendar-caption,
		.k-meta-header,
		.k-month-header {
			color: var(--theme-dark-color);
		}
	}
}
/*---------------Date Range Picker Styles End---------------*/

/*---------------Date Range Combo Box Styles Start---------------*/
.default-date-ranger-combo-box {
	background-color: var(--form-control-bg);
	color: var(--theme-dark-color);
	border: 1px solid var(--border-color-secondary);
	height: 2.75rem;
	border-radius: 0.3125rem;
	min-width: 17rem;
	max-width: 17rem;
	position: relative;
	&.max-w-unset {
		max-width: unset;
		.k-daterangepicker .k-floating-label-container {
			max-width: 8.25rem !important;
		}
	}
	&.disabled {
		background-color: var(--body-color) !important;
		opacity: 1 !important;
		cursor: not-allowed !important;
	}
	.k-daterangepicker {
		display: inline-flex;
		flex-direction: row !important;
		margin: 0 !important;
		height: 100%;
		position: relative;
		align-items: center !important;
		&:before {
			position: absolute;
			content: '\e108';
			left: 1rem;
			font-family: 'WebComponentsIcons';
			font-size: var(--fz-16px);
			line-height: 1.3125rem;
			color: var(--theme-text-color-secondary);
		}
		&:after {
			position: absolute;
			content: '\e015';
			right: 1rem;
			font-family: 'WebComponentsIcons';
			font-size: var(--fz-16px);
			line-height: 1.3125rem;
			color: var(--theme-text-color-secondary);
		}
		.k-label {
			display: none;
		}
		.k-floating-label-container {
			padding: 0 !important;
			height: 100%;
			.k-dateinput {
				border: 0 !important;
				outline: 0 !important;
				box-shadow: none !important;
				height: 100%;
				background-color: transparent !important;
				.k-input-inner {
					padding: 0 !important;
					font-size: var(--fz-14px);
					line-height: 1.3125rem;
					color: var(--theme-text-color-secondary);
					font-family: 'Gotham-Book', sans-serif !important;
					cursor: pointer;
				}
			}
			&:first-child {
				.k-input-inner {
					padding-left: 3.125rem !important;
				}
			}
			&:last-child {
				.k-input-inner {
					padding-right: 2.8125rem !important;
				}
			}
		}
	}
	.close-button {
		&.clearBtn {
			position: absolute;
			top: 0;
			right: 0.5rem;
			width: 2rem;
			height: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: var(--form-control-bg);
			color: var(--theme-text-color-secondary);
			font-size: var(--fz-16px);
		}
	}
	&.disabled {
		.close-button {
			&.clearBtn {
				background-color: var(--body-color) !important;
			}
		}
	}
}

/*---------------Date Range Combo Box Styles End---------------*/

/*---------------No Data Small Size Styles Start---------------*/
.no-data.sm-size {
	height: calc(100% - 3.5rem);
	.no-data-body {
		max-width: 14.875rem;
		max-height: 14.875rem;
		min-height: 14.875rem;
		.no-data-inner {
			max-width: 14.875rem;
			max-height: 14.875rem;
			min-height: 14.875rem;
			img {
				width: 8rem;
			}
		}
		h1 {
			font-size: var(--fz-20px);
			margin-top: 1rem;
		}
		&:after {
			max-width: 14.875rem;
			max-height: 14.875rem;
			min-height: 14.875rem;
		}
	}
}

/*---------------No Data Small Size Styles End---------------*/

/*---------------Checkbox Styles Starts ---------------*/

.inline-checkbox-group {
	display: inline-flex;
	align-items: center;
	gap: 1.5rem;
	.k-checkbox-label {
		font-weight: 600;
		font-size: var(--fz-14px);
		line-height: 150%;
		color: var(--theme-text-color-secondary);
	}
}
/*---------------Checkbox Styles End---------------*/

/*---------------Custom Gutter Styles Start---------------*/

.row-gutter-10px {
	margin-left: -0.3125rem !important;
	margin-right: -0.3125rem !important;

	.gutter-10px {
		padding-left: 0.3125rem !important;
		padding-right: 0.3125rem !important;
	}
}

/*---------------Custom Gutter Styles End---------------*/

/*---------------Custom Loader Styles Start---------------*/

.loader-primary {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.k-loader-segment {
		background-color: var(--primary-color);
	}
}
.loader-danger {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.k-loader-segment {
		background-color: var(--danger-lite-color);
	}
}

.loader-success {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.k-loader-segment {
		background-color: var(--success-color);
	}
}
.loader-warning {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.k-loader-segment {
		background-color: var(--warning-color);
	}
}

/*---------------Custom Loader Styles End---------------*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px var(--form-control-bg) inset !important;
	-webkit-text-fill-color: var(--theme-dark-color) !important;
	&.disabled {
		-webkit-box-shadow: 0 0 0 30px var(--body-color) inset !important;
	}
}

/*---------------Dark Theme Disable Input Styles Start---------------*/

.dark-theme {
	.form-control:disabled,
	.form-control.disabled,
	.default-upload-wrapper.disabled {
		background-color: var(--secondary-color) !important;
	}
}

/*---------------Dark Theme Disable Input Styles End---------------*/
/*---------------Dark Theme Disable Input Styles End---------------*/
.k-daterangepicker-popup {
	.k-calendar-nav-today {
		display: none;
	}
}
.button-pos-end {
	display: flex;
	justify-content: end;
}
.bottom-manufacture-end {
	display: flex;
	justify-content: end;
	gap: 1rem;
}
.custm-multiselect-scroll {
	overflow-y: auto !important;
}
/*---------------Date Time Combo Box Styles Start---------------*/
.default-date-time-combo-box {
	background-color: var(--form-control-bg);
	color: var(--theme-dark-color);
	border: 1px solid var(--border-color-secondary);
	height: 2.75rem;
	border-radius: 0.3125rem;
	min-width: 17rem;
	max-width: 17rem;
	position: relative;
	&.max-w-unset {
		max-width: unset;
	}
	&.disabled {
		background-color: var(--body-color) !important;
		opacity: 1 !important;
		cursor: not-allowed !important;
	}
	.date-time-picker-row {
		display: flex;
		flex-direction: row !important;
		margin: 0 !important;
		height: 100%;
		position: relative;
		align-items: center !important;
		.k-datetimepicker {
			border: 0 !important;
			outline: 0 !important;
			box-shadow: none !important;
			height: 100%;
			width: 13rem !important;
			background-color: transparent !important;
			display: flex;
			flex-direction: row-reverse;
			padding-left: 1rem !important;
			.k-input-inner{
				font-size: var(--fz-14px) !important;
				line-height: 1.3125rem !important;
				color: var(--theme-text-color-secondary) !important;
				font-family: "Gotham-Book", sans-serif !important;
				cursor: pointer;
			}
			.k-icon-button {
				border: 0;
				font-size: var(--fz-16px);
				line-height: 1.3125rem;
				color: var(--theme-text-color-secondary);
				background-color: transparent;
				background-image: none;
				padding: 0 !important;
				width: auto !important;
			}
			&:nth-child(2) {
				padding-left: 0 !important;
				
			}
		}
	}
}
.k-datetime-container {
    background-color: var(--theme-light-color) !important;
    border-color: var(--border-color-secondary) !important;
    .k-calendar, .k-calendar-content {
        background-color: var(--theme-light-color) !important;
        color: var(--theme-dark-color);
		
    }
	.k-calendar .k-calendar-view::after{
		box-shadow: unset !important;
	}
    .k-datetime-footer {
		.k-time-cancel{
			display:none !important;
		}
        .k-button-solid-primary {
            background-color: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
            color: var(--white-color) !important;
            outline: none !important;
            &:hover,
            &:focus {
                border-color: var(--primary-dark-color) !important;
                background-color: var(--primary-dark-color) !important;
                color: var(--white-color) !important;
            }
        }
    }
    .k-button-solid-base.k-selected {
        background-color: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
            color: var(--white-color) !important;
            outline: none !important;
            &:hover,
            &:focus {
                border-color: var(--primary-dark-color) !important;
                background-color: var(--primary-dark-color) !important;
                color: var(--white-color) !important;
            }
    }
    .k-calendar-nav.k-hstack{
        display: none !important;
    }
    .k-datetime-selector{
		.k-time-header .k-time-now{
            display: none !important;
        }
		.k-time-list{
			&::before, &::after{
				box-shadow: unset !important;
			}
		}
		.k-time-list-wrapper .k-title{
			color: var(--theme-dark-color) !important;
		}
		.k-time-header{
			.k-title{
				color: var(--theme-dark-color) !important;
			}
		}
    }
	.k-time-highlight{
		background-color: var(--theme-color) !important;
    	opacity: 0.2;
		z-index: 2 !important;
	}
	.k-date-tab.k-datetime-wrap{
		.k-datetime-footer{
			.k-time-accept{
				display: none !important;
			}
		}
	}
	.k-date-tab.k-datetime-wrap{
		.k-datetime-footer{
			.k-time-accept{
				display: none !important;
			}
		}
	}
	.k-time-list-wrapper{
		align-items: center !important;
		&.k-focus::before, &.k-focus::after{
			background-color: var(--theme-light-color) !important;
		}
		.k-time-list-item, .k-time-list .k-item {
			padding-top: 6px !important;
		}
	}

}

/*---------------Date Time Combo Box Styles End---------------*/
