/*---------------------Kendo Grid Styles Start - Light Theme---------------------------*/
.light-theme,
.dark-theme {
	.default-kendo-grid {
		border: 0;
		height: 100% !important;
		background-color: transparent;
		&.h-auto{
			height: auto !important;
			.k-grid-header{
				padding-right: 0;
			}
			.k-grid-container {
				.k-grid-content{
					overflow-y: visible;
					padding-right: 0;
				}
				&:after{
					display: none;
				}
			}
			.k-grid-norecords .k-table-td{
				height: auto !important;
			}
		}

		.k-grid-header {
			border: 0;
			background-color: transparent;

			.k-grid-header-wrap {
				border: 0;
				border-radius: 0.625rem;

				.k-table {
					width: 100% !important;
					margin-bottom: 0.15rem;

					.k-table-thead {
						background-color: var(--grid-header-bg-color);
					}

					tr {
						th {
							border: 0;
							border-right: 1px solid var(--grid-border-seperator) !important;
							font-weight: 700;
							font-size: var(--fz-14px);
							line-height: 150%;
							color: var(--kendo-grid-header);
							padding: 1rem 1.25rem 0.8125rem 1.25rem;
							background-color: var(--grid-header-bg-color) !important;
							text-align: left !important;

							.sort-wrapper {
								display: inline-flex;
								align-items: center;

								.sort-icon-wrap {
									display: inline-flex;
									padding-left: 1.313rem;
									flex-direction: column;
									gap: 2px;

									.chevron-arrow-up {
										width: 0;
										height: 0;
										border-left: 0.188rem solid transparent;
										border-right: 0.188rem solid transparent;
										border-bottom: 0.375rem solid var(--input-text-color);
										display: inline-block;

										&.chevron_active {
											border-bottom-color: var(--theme-color);
										}
									}

									.chevron-arrow-down {
										width: 0;
										height: 0;
										border-left: 0.188rem solid transparent;
										border-right: 0.188rem solid transparent;
										border-top: 0.375rem solid var(--input-text-color);
										display: inline-block;

										&.chevron_active {
											border-top-color: var(--theme-color);
										}
									}
								}

								.k-grid-header-menu {
									bottom: unset !important;
									&:hover {
										background-color: transparent !important;
									}
								}
							}
						}
					}
				}
			}
		}
		.k-toolbar {
			background-color: transparent !important;
			border:0 !important;
		}
		.k-grid-container {
			.k-grid-content {
				background-color: transparent;
				.k-grid-table-wrap {
					.k-table {
						width: 100% !important;

						tr {
							td {
								border: 0;
								border-right: 1px solid var(--grid-border-seperator) !important;
								border-bottom: 1px solid var(--grid-border-seperator);
								font-weight: 400;
								font-size: var(--fz-14px);
								line-height: 150%;
								color: var(--grid-body-text-color);
								background-color: var(--grid-body-bg-color) !important;
								padding: 1rem 1.25rem;
								word-break: break-word;
								&.disabled {
									background-color: var(--grid-body-diabled-bg-color) !important;
								}

								&:first-child {
									border-left: 1px solid var(--grid-border-seperator) !important;
								}
								ul{
									padding-left: 1rem;
									li{
										list-style: disc;
										display: list-item;
									}
								}
							}
							&.k-grid-norecords {
								td {
									border: 1px solid var(--grid-border-seperator) !important;
								}
							}
						}
					}
				}
			}
		}

		.k-grid-pager {
			border: 0;
			padding: 0;
			background-color: transparent;
			justify-content: flex-end;

			.k-pager-sizes,
			.k-pager-first,
			.k-pager-last {
				display: none;
			}

			.k-pager-info {
				display: none;
			}

			.k-pager-numbers-wrap {
				.k-dropdown-list {
					display: none !important;
				}
				.k-button {
					width: 1.75rem;
					height: 1.75rem;
					background-color: var(--grid-pg-btn-bg) !important;
					border: 1px solid var(--grid-pg-btn-border) !important;
					border-radius: 0.0625rem;
					margin-left: 0.625rem;
					&.k-pager-nav{
						color: var(--grid-pg-btn-color);
					}
					&:not([aria-label]){
						display: none;
					}
					
					&.k-pager-nav{
						&:not([aria-label]){
							display: inline-flex;
						}
						&.k-pager-first, &.k-pager-last{
							&:not([aria-label]){
								display: none;
							}
						}
					}
				}

				.k-pager-numbers {
					display: flex !important;
					.k-button {
						color: var(--grid-pg-btn-color) !important;

						&.k-selected {
							border-radius: 0.125rem;
							background-color: var(--grid-pg-btn-bg) !important;
							color: var(--theme-color) !important;

							&:before {
								background: var(--warning-color) !important;
								opacity: 0.15;
								border-radius: 0.125rem;
							}
						}
					}
				}
			}
		}

		.k-table-alt-row {
			background-color: var(--grid-body-bg-color) !important;
		}

		.ellipsis-dropdown {
			&.approved {
				font-weight: 600;
				font-size: var(--fz-12px);
				display: inline-flex;
				align-items: center;
				justify-content: center;
				min-height: 1.813rem;
				border-radius: 0.25rem;
				padding: 0.125rem 0.75rem;
				border: 1px solid var(--success-color);
				color: var(--success-color);
			}
			&.reviewed {
				font-weight: 600;
				font-size: var(--fz-12px);
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: 0.125rem 0.75rem;
				min-height: 1.813rem;
				border-radius: 0.25rem;
				border: 1px solid var(--theme-color);
				color: var(--theme-color);
			}

			.dropdown-menu {
				left: unset !important;
				width: 11.5rem !important;
				&:before,
				&:after {
					display: none;
				}
				.dropdown-item{
					 .custom-action-dropdown {
						white-space: normal;
						word-break: break-word;
					 }
				}
			}
		}
	}
}

.k-i-caret-alt-left::before {
	content: '\e016' !important;
}

.k-i-caret-alt-right::before {
	content: '\e014' !important;
}
.k-list-item {
	&.k-selected {
		background-color: rgba(var(--warning-color-rgba), 0.15) !important;
    	color: var(--theme-color) !important;
		&:hover {
			color: var(--theme-color) !important;
		}
	}
	&:hover {
		background-color: var(--list-item-hover-color) !important;
	}
}
.sort-wrapper {
	cursor: pointer;
}

.filter-dropdown-wrap {
	padding: 8px;
	box-sizing: border-box;
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	gap: 8px;
	.form-control {
		padding: 0rem;
		font-size: var(--fz-14px);
		line-height: 1.4285714286;
	}
}
.filter-type-btn-wrap {
	// display: flex;
	// justify-content: space-between;
	padding-block: unset !important;
	padding-inline: unset !important;
	.k-button {
		// padding-inline: 35px !important;
		// font-size: 14px !important;
		// line-height: 1.4285714286 !important;
		// color: #424242 !important;
		padding-inline: 0 !important;
	}
	.kendo-filter-bgcolor {
		background-color: #1268b3 !important;
		border-color: #1268b3 !important;
		color: var(--white-color) !important;
	}
}
.k-grid-columnmenu-popup {
	.k-columnmenu-item-wrapper {
		padding: 1rem 0.75rem !important;
	}
}
.k-daterangepicker {
	flex-flow: column nowrap !important;
	align-items: flex-start !important;
	margin-bottom: 0.5rem;
	.k-floating-label-container {
		width: 100% !important;
		.k-dateinput {
			width: 100% !important;
			background-color: var(--form-control-bg) !important;
    		border: 1px solid var(--border-color-secondary) !important;
			.k-input-inner{
				color: var(--grid-body-text-color);
			}
		}}}
.k-grid-container{
	position: relative;
	&:after{
		position: absolute;
		content: "";
		background: var(--scrollbar-track);
		z-index: 99;
		width: 0.5rem;
		height: 0.5rem;
		bottom: 0;
		right: 0;
	}
}
/*---------------------Kendo Grid Styles End - Light Theme---------------------------*/
